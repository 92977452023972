.resetPasswordContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  min-height: 100%;
  flex-wrap: wrap;
  flex-direction: column;
  width: 100%;

  .loginFlexMain {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 60px;
    flex: 1;
    max-width: 90%;
    width: 100%;
    margin-top: 4rem;
  }

  .loginSiteLogo {
    // flex-basis: 100%;
    align-self: flex-start;
    // margin-bottom: auto;
  }
  .imageSection {
    max-width: 600px;
    img {
      display: block;
      width: 100%;
      height: auto;
    }
  }

  form {
    display: flex;
    flex-direction: column;
  }
  .resetPassForm {
    text-align: center;
    width: 100%;
    max-width: 600px;

    .resetPass {
      display: flex;
      flex-direction: row;
      height: 100%;
      .resetPassSectionOne {
        flex: 1 1 50%;
        width: 100%;

        .resetPassForm {
          display: flex;
          align-items: center;
          margin: 0 auto;
          width: 100%;
          max-width: 100%;
          justify-content: center;
          .resetPassFormInner {
            display: flex;
            flex-direction: column;
            gap: 10px;
            width: 100%;
            max-width: 480px;
            p {
              font-size: var(--secondaryFont);
              color: var(--textColor);
              // padding-bottom: 5px;
            }
            input {
              font-size: var(--secondaryFont);
              padding: 10px 7px;
              border-radius: 5px;
              width: 100%;
              border: 1px solid var(--blackColor);
              &:focus {
                border-color: var(--secondaryColor);
              }
            }

            .formInnerBtnSection {
              display: flex;
              flex-wrap: wrap;
              justify-content: center;
              margin-top: 20px;

              button {
                width: 100%;
                img {
                  filter: invert(100%) sepia(4%) saturate(16%) hue-rotate(86deg)
                    brightness(104%) contrast(104%);
                }
              }
            }
            h1 {
              font-size: 1.5rem;
              font-weight: 600;
            }
          }
        }
      }
    }
  }
  .backToLogin {
    display: flex;
    justify-content: center;
    gap: 5px;
    align-items: flex-start;
  }
}

@media screen and (max-width: 990px) {
  .resetPasswordContainer {
    width: 100%;
    // padding: 20px;
    .resetPassForm {
      .resetPass {
        .resetPassSectionOne {
          .resetPassForm {
            height: 100%;
            padding: 0 20px;
          }
        }
      }
    }
    .imageSection {
      display: none;
    }
  }
}
@media screen and (max-width: 550px) {
  .resetPasswordContainer {
    width: 100%;
    // align-items: flex-start;
    // padding: 20px;
    .resetPassForm {
      .resetPass {
        .resetPassSectionOne {
          .resetPassForm {
            height: 100%;
            padding: 0 20px;
          }
        }
      }
    }
    .imageSection {
      display: none;
    }
  }
}
