.invitePopupContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 650px;
  height: 100%;
  margin: auto;
  z-index: 2;
  border: none !important;
  outline: none !important;
  .invitePopupContainerInner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;
    overflow: auto;
    z-index: 2;

    .uploadFileHeader {
      width: 100%;
      background-color: var(--secondaryColor);
      color: var(--whiteColor);
      display: flex;
      padding: 15px 30px;
      border-radius: 15px 15px 0 0;
      justify-content: space-between;
      align-items: center;
    }

    .uploadFileBody {
      display: flex;
      background-color: var(--whiteColor);
      display: flex;
      flex-direction: column;
      border-radius: 0 0 15px 15px;
      justify-content: space-between;
      max-height: 85%;

      .uploadFileBodyInner {
        display: flex;
        flex-direction: column;
        height: 100%;
        // max-height: 70vh;
        overflow: auto;

        // gap: 25px;
        .optionContainer {
          display: flex;
          justify-content: space-between;
          width: 100%;

          .shadow {
            width: 100%;
            padding: 10px 15px;
            color: #b3b3b3;
            display: flex;
            // justify-content: space-between;
            gap: 10px;
            align-items: center;
            cursor: pointer;
            justify-content: center;

            p {
              font-weight: normal;
              font-size: 16px;
            }
          }

          .tabs {
            cursor: pointer;
            width: 100%;
            padding: 10px;
            border-bottom: 2px solid var(--secondaryColor);
            display: flex;
            gap: 10px;
            align-items: center;
            justify-content: center;

            p {
              font-weight: 600;
              font-size: 16px;
              color: var(--primaryColor);
            }
          }
        }

        .uploadFileRow {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          padding: 15px 30px;
          // overflow: auto;
          height: 150%;
          // max-height: 65vh;
          gap: 12px;
          width: 100%;
          .file {
            input {
              margin: 0;
            }
          }

          .uploadDoc {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            border: 1px dashed var(--secondaryColor);
            border-radius: 15px;
            // gap: 15px;
            // background: rgba(0, 151, 206, 0.12);
            svg {
              max-width: 25%;
            }
          }

          .tableContainer {
            width: 100%;
            overflow: auto;
            height: 100%;
            max-height: 400px;

            table {
              border: 1px solid var(--tableBorderColor);
              thead {
                // background-color: white;
                border-bottom: 1px solid var(--tableBorderColor);

                tr {
                  th {
                    border: none;
                    // padding: 5px !important;
                    min-width: fit-content !important;
                  }
                }
              }

              tbody {
                tr {
                  border: none;
                }
              }
            }
          }

          input {
            width: 100%;
            margin: 6px;
          }

          .documentList {
            .tableheader {
              display: flex;
              width: 100%;
              justify-content: space-between;
            }

            .tableData {
              .doumentListInner {
                display: flex;
                width: 100%;
                justify-content: space-between;
                align-items: center;
                max-height: 165px;
                overflow: auto;

                span {
                  font-size: 13px;
                  padding: 0;
                  margin: 0;
                  padding-top: 4px;
                }

                p {
                  // color: var(--textColor);
                  font-size: 13px;
                  padding: 0;
                  margin: 0;
                  // &:last-of-type {
                  //   font-weight: bold;
                  // }
                }

                .document {
                  display: flex;
                  gap: 5px;
                  width: 24%;

                  // align-items:flex-start;
                  p {
                    font-size: 5px;
                  }

                  input {
                    max-width: 15px !important;
                    margin: 5px;
                  }
                }
              }
            }
          }

          .documentUploadSection {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 20px;
            width: 100%;

            .searchBar {
              width: 100%;
              display: flex;
              justify-content: flex-end;

              // max-width: 200px;
              > div {
                padding: 2px 6px;
                width: 100%;
                max-width: 100%;
              }
            }

            h5 {
              font-size: var(--primayFont);
              // font-weight: 550;
            }
          }
        }

        .dropdownContainer {
          width: 100%;
          gap: 20px;
          display: flex;

          .dropdownData {
            display: flex;
            flex-direction: column;
            // gap: 20px;
            // flex: 1 1 40%;
            width: 100%;

            // padding: 50px 0px;
            .dropdown {
              width: 100%;
            }
          }

          .radiodata {
            // flex: 1 1 60%;
            display: flex;
            gap: 5px;
            align-items: center;

            p {
              font-size: 14px;
            }

            input {
              max-width: 15px !important;
            }
          }
        }
      }

      .buttonPanel {
        width: 100%;
        display: flex;
        gap: 15px;
        justify-content: flex-end;
        align-items: center;
        // padding: 15px 0px;

        a {
          color: var(--primaryColor);
          text-decoration: underline;
          font-weight: 600;
        }
      }
    }
  }
}
.pagination {
  display: flex;
  justify-content: center;
  & > div {
    & > div {
      margin: 0 !important;
    }
  }
}
.tableHeading {
  font-size: 16px;
}
.uploadFileRow1 {
  width: 100%;
  flex-wrap: wrap;
  input {
    width: 100%;
  }

  display: flex;
  width: 100%;
  // justify-content: space-between;
  align-items: center;
  gap: 20px;

  .form {
    display: flex;
    align-items: flex-start;
    gap: 5px;
    justify-content: flex-end;
    flex-direction: column;
    flex: 1 1 48%;

    input {
      padding: 8px 5px;
      border-radius: 5px;
      width: 100%;
    }

    label {
      max-width: 100%;
    }
  }

  .dropdownData {
    display: flex;
    flex-direction: column;
    // gap: 20px;
    flex: 1 1 40%;
    // padding: 50px 0px;
  }

  .radiodata {
    flex: 1 1 60%;
    display: flex;
    gap: 5px;
    align-items: center;

    input {
      max-width: 15px !important;
    }
  }
}

.btnContainer {
  margin-top: 30px;
  width: 100%;
  justify-content: flex-end;
  display: flex;
  width: 100%;
  gap: 15px;
}

.form-group {
  width: 50%;
}
.assessmentheader {
  display: flex;
  justify-content: left;
  gap: 10px;
  align-items: center;
  padding-bottom: 10px;
  h4 {
    padding: 0px;
  }
  & > div {
    margin-left: auto;
    svg {
      max-width: 14px;
    }
  }
}
h4 {
  font-size: calc(var(--primaryFont) + 4px);
  color: var(--primaryColor);
  font-weight: 600;
  align-items: center;
  // padding-top: 20px;
  padding-bottom: 10px;
}

@media screen and (max-width: 990px) {
  .invitePopupContainer {
    .invitePopupContainerInner {
      max-width: 85%;
      .uploadFileBody {
        .uploadFileBodyInner {
          .uploadFileRow {
            // max-height: 55vh;
            overflow: auto;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 550px) {
  .invitePopupContainer {
    .invitePopupContainerInner {
      max-width: 300px;
      top: 18%;

      .addChapterRow {
        gap: 20px;

        .userLogin {
          gap: 10px;

          .userLoginInner {
            flex-direction: column;
            gap: 10px;
            padding: 0;
          }
        }

        .inviteUserBtn {
          // flex-direction: column;
          justify-content: center;
          margin-top: 20px;
        }
      }
    }
  }
}
