.resetPasswordContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  min-height: 100%;
  flex-wrap: wrap;
  flex-direction: column;
  width: 100%;

  .loginFlexMain {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    flex: 1;
    width: 80%;
  }

  .loginSiteLogo {
    flex-basis: 100%;
    align-self: flex-start;
    // margin-bottom: auto;
  }
  .imageSection {
    width: 100%;

    img {
      display: block;
      width: 620px;
      height: auto;
      max-width: 100%;
    }
  }

  form {
    display: flex;
    flex-direction: column;
  }
  .resetPassForm {
    text-align: center;
    width: 100%;
    max-width: 600px;
  }
}
.requirements {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  font-size: var(--secondaryFont);
  margin-top: 20px;

  h4 {
    font-size: var(--primaryFont);
    color: var(--primaryColor);
    font-weight: 600;
    padding-bottom: 8px;
  }
  .tick {
    margin-right: 5px;
  }
  .fulfilled {
    color: #1e9e1e;
  }
}
.resetPass {
  display: flex;
  flex-direction: row;
  height: 100%;
  .resetPassSectionOne {
    flex: 1 1 50%;

    width: 100%;

    .resetPassForm {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 auto;
      width: 100%;
      max-width: 100%;
      .resetPassFormInner {
        display: flex;
        flex-direction: column;
        gap: 10px;
        width: 100%;
        max-width: 500px;

        .loginInputs {
          display: flex;
          flex-direction: column;
          gap: 20px;
        }
        .eyeIconPassword {
          position: absolute;
          right: 10px;
          max-width: 20px;
          top: 0;
          left: auto;
          bottom: 0;
          margin: auto;
          cursor: pointer;
        }

        h1 {
          font-size: 1.5rem;
          font-weight: 600;
        }
        p {
          font-size: var(--secondaryFont);
          color: var(--textColor);
          padding-bottom: 20px;
        }
        input {
          font-size: var(--secondaryFont);
          padding: 10px 7px;
          border-radius: 5px;
          width: 100%;
          border: 1px solid var(--blackColor);
          &:focus {
            border-color: var(--secondaryColor);
          }
        }

        .formInnerBtnSection {
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          margin-top: 20px;
          // button {
          //   img {
          //     filter: invert(100%) sepia(4%) saturate(16%) hue-rotate(86deg)
          //       brightness(104%) contrast(104%);
          //   }
          // }
        }
        .requirements {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          text-align: left;
          font-size: var(--secondaryFont);
          margin-top: 20px;

          h4 {
            font-size: var(--primaryFont);
            color: var(--primaryColor);
            font-weight: 600;
          }
          .fulfilled {
            color: #1e9e1e;
          }
        }
      }
    }
  }
}
.backToLogin {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}
@media screen and (max-width: 990px) {
  .resetPass {
    .resetPassSectionOne {
      .resetPassForm {
        height: 100%;
        padding: 0 20px;
      }
    }
  }
  .resetPasswordContainer {
    .imageSection {
      display: none;
    }
  }
}

@media screen and (max-width: 550px) {
  .resetPass {
    .resetPassSectionOne {
      .resetPassForm {
        height: 100%;
        padding: 0 20px;
      }
    }
  }
}
