.addLocationBtnInner {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 90%;
  padding: 35px;
  gap: 40px;
  background-color: var(--whiteColor);

  .addLocationBtnHeading {
    display: flex;
    gap: 10px;
    justify-content: center;
    flex-direction: column;
    h3 {
      font-size: 1.5rem;
      font-weight: 600;
    }
  }
  .addLocationBtnForm {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    text-align: left;

    .checkboxSection {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
    input {
      width: 100%;
      border-radius: 5px;
      padding: 8.2px 10px;
    }
    input[type="checkbox"] {
      max-width: 15px;
      width: 15px;
      height: 15px;
    }
    label {
      font-weight: normal;
      font-size: var(--secondaryFont);
    }
    .orgRegionsParent {
      display: flex;
      flex-direction: column;

      h3 {
        padding-top: 10px;
        padding-bottom: 5px;
        font-size: var(--secondaryFont);
        font-weight: 600;
        color: var(--textColor);
        text-transform: uppercase;
      }

      .orgRegions {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: 10px;
        .regionBranch {
          text-align: left;
          padding: 20px 0 0 0;
          font-size: calc(var(--primaryFont) - 2px);
          font-weight: 600;
          color: var(--textColor);
          text-transform: uppercase;
          flex-basis: 100%;
        }

        .editTypeInput {
          display: flex;
          justify-content: space-between;
          flex-direction: column;
          align-items: flex-start;
          gap: 0px;
          flex: 1 1 0;
          position: relative;
          svg {
            position: absolute;
            right: 5px;
            bottom: 12px;
            max-width: 16px;
            height: auto;
          }
        }
        select {
          width: 100%;
          border: 1px solid var(--blackColor);
          border-radius: 5px;
          background-color: var(--whiteColor);
          height: 42px;
          padding: 4px 10px;
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;
          position: relative;
        }
      }
      button {
        height: fit-content;
        align-self: flex-end;
        margin-bottom: 10px;
      }
    }
    .editTypeInput {
      position: relative;
      svg {
        position: absolute;
        right: 5px;
        bottom: 12px;
        max-width: 16px;
        height: auto;
      }

      p {
        color: var(--redColor);
        font-size: calc(var(--secondaryFont) - 1px);
        position: absolute;
        top: 97%;
      }
      & > div > ul {
        height: 42px;
      }
    }
  }
  .pageBtn {
    justify-content: flex-end;
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 10px 0px 0px 0px;
    width: 100%;

    button {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 5px;
    }
  }
}
