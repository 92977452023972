.manageAssessmentContainer {
  min-height: 100vh;

  .manageAssessmentHeader {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 5px 20px;

    p {
      color: var(--textColor);
      display: flex;
      gap: 20px;
      margin: 0;
    }
  }

  .breadCrumb {
    display: flex;
    width: 100%;

    .fileInput {
      width: 100%;
      max-width: fit-content;
    }
  }

  .manageAssessmentBody {
    display: flex;
    flex-direction: column;
    gap: 20px;
    // padding: 20px;
    height: 100%;
    // box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    // border: 1px solid rgba(0, 0, 0, 0.1);
    background-color: var(--whiteColor);

    .assessmentRow {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-radius: 5px;
      gap: 15px;
      width: 100%;
      flex-wrap: wrap;

      .filtrSearchDiv {
        display: flex;
        align-items: center;
        gap: 15px;
        width: 100%;
        max-width: 70%;
        justify-content: space-between;
      }
    }

    .assessmentMain {
      width: 100%;
      // height: 100%;
      // max-height: 424px;

      max-height: 60%;

      overflow: auto;
      margin: 0 auto;
      // height: 100%;

      .assesmentLoader {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 60vh;
      }
    }

    .assessmentSpanDiv {
      display: flex;
      justify-content: flex-start;
      gap: 10px;

      .assessmentSpan {
        display: flex;
        gap: 8px;
        flex-wrap: wrap;

        h4 {
          text-transform: uppercase;
          font-size: 12px;
          flex-basis: 100%;
        }

        span {
          background-color: var(--whiteColor);
          border-radius: 12px;
          padding: 2px 10px;
          border: 1px solid var(--textColor);
          font-size: 12px;

          a {
            margin-right: 8px;
          }
        }
      }
    }
  }
}

.assessmentheader {
  display: flex;
  justify-content: left;
  gap: 10px;
  align-items: center;
  padding-bottom: 10px;
  h4 {
    font-size: calc(var(--primaryFont) + 4px);
    color: var(--primaryColor);
    font-weight: 600;
    align-items: center;
    padding-top: 10px;
  }
  & > div {
    margin-left: auto;
    svg {
      max-width: 14px;
    }
  }
}

@media screen and (max-width: 750px) {
  .manageAssessmentContainer {
    .manageAssessmentBody {
      .assessmentRow {
        .searchContainer {
          width: 100%;

          > div {
            max-width: 100% !important;
          }
        }

        .filtrSearchDiv {
          display: flex;
          align-items: center;
          gap: 15px;
          width: 100%;
          max-width: 100% !important;
          justify-content: space-between;
        }
      }
    }
  }
}
