.tooltipContainer {
  position: relative;
  cursor: pointer;
  z-index: 13;
  // z-index: 6;
  .tooltip {
    background-color: rgba(0, 0, 0, 0.8);
    color: var(--whiteColor);
    position: absolute;
    bottom: 100%;
    padding: 2px 8px;
    max-width: 160px;
    width: max-content;
    min-width: fit-content;
    left: 0;
    z-index: 13;
    border-radius: 5px;
    font-size: var(--secondaryFont);
    font-weight: normal;
    
    &:after {
      content: "";
      position: absolute;
      left: 2px;
      top: 100%;
      width: 0;
      height: 0;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      border-top: 6px solid #000;
      opacity: 0.75;
      clear: both;
      z-index: 13;
    }
  }

  .rightTooltip {
    background-color: rgba(0, 0, 0, 0.8);
    color: var(--whiteColor);
    position: absolute;
    bottom: 100%;
    padding:5px 10px;
    max-width: 260px;
    max-height: 100px;
    overflow: auto;
    min-width: 230px;
    right: 0;
    z-index: 13;
    border-radius: 5px;
    font-size: var(--secondaryFont);
    font-weight: normal;
    scrollbar-width: thin; /* Firefox */
    scrollbar-color: rgba(255, 255, 255, 0.5) transparent; /* Firefox */

    &::-webkit-scrollbar {
        width: 6px; /* Width of the scrollbar */
    }

    &::-webkit-scrollbar-track {
        background: transparent; /* Background of the scrollbar track */
    }

    &::-webkit-scrollbar-thumb {
        background: rgba(255, 255, 255, 0.5); /* Color of the scrollbar thumb */
        border-radius: 4px; /* Round edges */
    }

    &::-webkit-scrollbar-thumb:hover {
        background: rgba(255, 255, 255, 0.8); /* Lighter color on hover */
    }
    &:after {
      content: "";
      position: absolute;
      right: 2px;
      top: 100%;
      width: 0;
      height: 0;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      border-top: 6px solid #000;
      opacity: 0.75;
      clear: both;
    }
  }
  
 
}
