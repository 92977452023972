.tableContainer {
  display: flex;
  justify-content: center;
  // margin-top: 50px;
  flex-direction: column;

  .tableQuestionContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 13px;
    color: black;
  }
  .biaTableContainer {
    display: flex;
    gap: 8px;
    flex-direction: column;
    width: 100%;
    max-width: 90vw;
    overflow: auto;
  }
  .table {
    width: 100%;

    .tableHeading {
      text-align: left;
      background-color: var(--backgroundColor);
      position: sticky;
      top: -1px;

      th {
        color: var(--primaryColor);
        padding: 12px 15px;
        font-size: 12px;
        font-weight: 600;
        max-width: 200px;
        min-width: 150px;
        white-space: normal;
        border: 1px solid var(--tableBorderColor);
      }
    }
  }

  .tableBody {
    background-color: #fff;

    tr {
      border-bottom: 1px solid var(--tableBorderColor);
      border-left: 1px solid var(--tableBorderColor);
      border-right: 1px solid var(--tableBorderColor);

      td {
        padding: 10px 15px;
        font-size: 12px;
        font-weight: normal;
        // max-width: 300px;
        // min-width: 200px;
        // overflow-wrap: break-word;

        a {
          font-size: 10px;
          font-weight: bold;
          color: var(--primaryColor);
          cursor: pointer;
          text-decoration: underline;
        }

        .actionBtn {
          display: flex;
          // justify-content: center;
          max-width: max-content;
          border-radius: 5px;
          gap: 10px;
          cursor: pointer;
        }
      }
    }
  }
}
.floating{
  float: right;
}
.iconContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  max-width: fit-content;
  background: var(--whiteColor);
  border: none;
  cursor: pointer;
}

.editCellContainer {
  display: flex;
  align-items: center;
  justify-content: "center";
  gap: 10px;
}

.btnContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  max-width: fit-content;
  background: var(--secondaryColor);
  border-radius: 5px;
  opacity: 1;
  border: 1px solid var(--secondaryColor);
  line-height: inherit;
  padding: 6px 10px;
  font-weight: bold;
  font-size: var(--secondaryFont);
  color: var(--whiteColor);
  margin-right: 0px;
  text-align: center;
  align-items: center;
  gap: 5px;
  flex-direction: row;
  cursor: pointer;
}

.inputField {
  display: block;
  width: 100%;
  max-width: 100%;
  gap: 0px;
  font-weight: normal;
  border-radius: 5px;
  padding: 5px 10px;
  border: 1px solid var(--tableBorderColor);
  outline: none;
}

.footerBtnContainer {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  button {
    margin: 10px 0;
  }
}
