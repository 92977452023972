.manageAssessmentContainer {
  min-height: 100%;
  .manageAssessmentBody {
    display: flex;
    flex-direction: column;
    gap: 20px;
    // padding: 20px;
    height: 100%;
    // border: 1px solid rgba(0, 0, 0, 0.1);
    background-color: var(--whiteColor);
    flex: 1 1;
    align-items: center;
    justify-content: center;
    img {
      max-width: 80%;
      width: 600px;
      margin: 0 auto;
    }
    h1 {
      color: var(--primaryColor);
    }
  }
}
