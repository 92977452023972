.uploadFileBody {
  border-radius: 0 0 15px 15px;
  display: flex;
  background-color: var(--whiteColor);
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-height: 590px;

  .uploadFileRow {
    width: 100%;
    flex-wrap: wrap;
    align-items: flex-start;

    .inputContainer {
      width: 100%;

      input {
        margin: 0 !important;
        width: 100%;
      }
    }

    input {
      width: 100%;
    }

    display: flex;
    width: 100%;
    // justify-content: space-between;
    align-items: center;
    gap: 20px;

    .form {
      display: flex;
      align-items: flex-start;
      gap: 5px;
      justify-content: flex-end;
      flex-direction: column;
      flex: 1 1 48%;

      input {
        padding: 8px 5px;
        border-radius: 5px;
        width: 100%;
      }

      label {
        max-width: 100%;
      }
    }

    .dropdownData {
      display: flex;
      flex-direction: column;
      // gap: 20px;
      flex: 1 1 40%;
      // padding: 50px 0px;
    }

    .radiodata {
      flex: 1 1 60%;
      display: flex;
      gap: 5px;
      align-items: center;

      input {
        max-width: 15px !important;
      }
    }
  }

  .btnContainer {
    width: 100%;
    justify-content: flex-end;
    display: flex;
    width: 100%;
    gap: 15px;
  }
}


.form-group {
  width: 50%;
}

@media screen and (max-width: 990px) {
  .invitePopupContainer {
    .invitePopupContainerInner {
      max-width: 75%;
      margin-left: 65px;
      margin: 0 auto;
    }
  }
}

@media screen and (max-width: 550px) {
  .invitePopupContainer {
    .invitePopupContainerInner {
      max-width: 75%;
      margin-left: 65px;
      margin: 0 auto;

      .addChapterRow {
        gap: 20px;

        .userLogin {
          gap: 10px;

          .userLoginInner {
            flex-direction: column;
            gap: 10px;
            padding: 0;
          }
        }

        .inviteUserBtn {
          // flex-direction: column;
          justify-content: center;
          margin-top: 20px;
        }
      }
    }
  }
}