.searchBar {
  display: flex;
  border: 1px solid var(--blackColor);
  align-items: center;
  gap: 6px;
  padding: 8px 10px;
  border-radius: 5px;
  min-width: 180px;
  max-width: 300px;
  width: 100%;
  position: relative;

  input {
    border: none;
    width: 100% !important;
  }
  a {
    cursor: pointer;
  }
  svg {
    min-width: 15px;
    max-width: 15px;
    // margin-top: 6px;
  }
  .searchIcon {
    svg {
      max-width: 12px;
      min-width: 12px;
      position: absolute;
      z-index: 1;
      right: 5px;
      top: 12px;
      height: auto;
      cursor: pointer;
    }
  }
}
