.viewComments {
  display: flex;
  align-items: flex-start;
  // justify-content: space-between;
  gap: 10px;
  position: relative;

  &::after{
    content:''; 
    border-bottom: 1px solid var(--tableBorderColor);
    position: absolute;
    bottom: -10px;
    width: 100%;
    left: 0;
    
  }
  &:last-child::after{
display: none;

  }
  .viewCommentsUser {
    display: flex;
    flex-direction: column;
    h5 {
      font-size: var(--secondaryFont);
      font-weight: 600;
    }
    p {
      font-size: var(--secondaryFont);
    }
  }
  span {
    color: var(--textColor);
    font-size: calc(var(--secondaryFont) - 2px);
    margin-left: auto;
  }
  svg {
    margin-top: 2px;
  }
}
