.manageAssessmentContainer {
  min-height: 100%;
  overflow: auto;

  .manageAssessmentHeader {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 5px 20px;

    p {
      color: var(--textColor);
      display: flex;
      gap: 20px;
      margin: 0;
    }
  }

  .authData {
    // display: flex;
    // flex-direction: column;
    // align-items: flex-end;
    // flex: 1 1 50%;
    display: flex;
    justify-content: space-between;
    width: 100%;
    background-color: var(--mainBgColor);
    padding: 9px 8px 9px 9px;
    .loggedInUser {
      display: flex;
      flex-direction: column;
      justify-content: right;
      flex: 1 1 70%;

      .loggedInTime {
        text-align: "center";
        font-size: "14px";
      }
      .loggedInTimeLabel {
        font-weight: normal;
      }

      .loggedInUserContainer {
        display: flex;
        gap: 10px;
        border-radius: 10px;
        // align-items: center;
        width: 100%;
        // max-width: 180px;
        // max-width: 250px;
        position: relative;
        // padding: 10px 10px 20px;
        justify-content: flex-end;
        cursor: pointer;
        &:hover {
          .logoutUi {
            display: flex;
          }
        }
        .logoutUi {
          position: absolute;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          top: 95%;
          width: 100%;
          max-width: 180px;
          margin: 0 auto;
          transition: all 0.5s ease;
          display: none;
          background-color: var(--whiteColor);
          padding: 5px 5px;
          border-radius: 5px;
          -webkit-box-shadow: 0px 3px 4px 0px rgba(202, 202, 202, 1);
          -moz-box-shadow: 0px 3px 4px 0px rgba(202, 202, 202, 1);
          box-shadow: 0px 3px 4px 0px rgba(202, 202, 202, 1);
          a {
            color: var(--primaryColor);
            text-decoration: none;
          }
        }

        .userInitial {
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          width: 100%;
          max-width: 40px;
          height: 40px;
          // flex: 1 1 50%;
          background-color: var(--secondaryColor);

          p {
            font-size: var(--primaryFont);
            color: var(--whiteColor);
            margin: 0;
            text-transform: uppercase;
            font-weight: bolder;
            padding: 5px;
          }
        }
        .userInfo {
          // flex: 1 1 50%;
          display: flex;
          flex-direction: column;

          p {
            margin: 0;
            &:nth-of-type(1) {
              font-weight: 600;
              font-size: 14px;

              color: var(--primaryColor);
            }
            &:nth-of-type(2) {
              font-size: 13px;
              color: var(--textColor);
            }
          }
        }
      }
    }
  }

  .breadCrumb {
    display: flex;
    width: 100%;

    .fileInput {
      width: 100%;
      max-width: fit-content;
    }
  }

  .manageAssessmentBody {
    display: flex;
    flex-direction: column;
    // gap: 10px;
    // padding: 20px;
    height: 100%;
    // box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    // border: 1px solid rgba(0, 0, 0, 0.1);
    background-color: var(--whiteColor);
    // padding: 9px 8px 9px 9px;
    // padding: 0px 20px 0px 20px;

    .assessmentRow {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-radius: 5px;
      gap: 15px;
      width: 100%;
      flex-wrap: wrap;

      .filtrSearchDiv {
        display: flex;
        align-items: center;
        gap: 15px;
        width: 100%;
        max-width: 50%;
        justify-content: space-between;
      }
    }

    .assessmentMain {
      width: 100%;
      // height: 100%;
      // max-height: 424px;
      // max-height: 60%;
      // overflow: auto;
      margin: 0 auto;

      margin-top: 20px;
      // height: 100%;
      table {
        tr {
          td {
            min-width: 200px;
            overflow-wrap: break-word;
            &:last-child {
              min-width: fit-content;
            }
          }
        }
      }


    }

    .assessmentSpanDiv {
      display: flex;
      justify-content: flex-start;
      gap: 10px;

      .assessmentSpan {
        display: flex;
        gap: 8px;
        flex-wrap: wrap;

        h4 {
          text-transform: uppercase;
          font-size: 12px;
          flex-basis: 100%;
        }

        span {
          background-color: var(--whiteColor);
          border-radius: 12px;
          padding: 2px 10px;
          border: 1px solid var(--textColor);
          font-size: 12px;

          a {
            margin-right: 8px;
          }
        }
      }
    }
  }
}
.assesmentLoader {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  // height: 100%;
  min-height: 50vh;
}
.noDataFound{
   margin: 30px;
   text-align: center;
}
@media screen and (max-width: 750px) {
  .assessmentRow {
    .searchContainer {
      width: 100%;
      > div {
        max-width: 100% !important;
      }
    }
    .filtrSearchDiv {
      display: flex;
      align-items: center;
      gap: 15px;
      width: 100%;
      max-width: 100% !important;
      justify-content: space-between;
    }
  }
}
