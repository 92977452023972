.invitePopupContainer {
  width: 100%;
  display: flex;
  justify-content: safe center;
  align-items: center;
  flex-direction: column;
  max-width: 600px;
  height: 98%;
  overflow-y: auto;
  // position: fixed;
  top: 10px;
  bottom: 10px;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 2;
  border: none !important;
  outline: none !important;

  // &:after {
  //   content: "";
  //   position: fixed;
  //   width: 100%;
  //   height: 100%;
  //   top: 0;
  //   left: 0;
  //   right: 0;
  //   bottom: 0;
  //   background-color: rgba(0, 0, 0, 0.5);
  //   background-position: center;
  //   background-repeat: no-repeat;
  // }

  .invitePopupContainerInner {
    // display: flex;
    // flex-direction: column;
    // justify-content: center;
    width: 100%;
    border-radius: 15px;
    // overflow: hidden;
    z-index: 2;

    .uploadFileHeader {
      width: 100%;
      background-color: var(--secondaryColor);
      color: var(--whiteColor);
      display: flex;
      padding: 15px 30px;
      border-radius: 15px 15px 0 0;
      justify-content: space-between;
      align-items: center;
    }

    .uploadFileBody {
      padding: 15px 30px;
      border-radius: 0 0 15px 15px;
      display: flex;
      background-color: var(--whiteColor);
      display: flex;
      flex-direction: column;
      gap: 20px;
      max-height: 590px;

      .uploadFileRow1 {
        width: 100%;
        flex-wrap: wrap;
        input {
          width: 100%;
        }

        display: flex;
        width: 100%;
        // justify-content: space-between;
        align-items: flex-start;
        gap: 20px;

        .form {
          display: flex;
          align-items: flex-start;
          gap: 5px;
          justify-content: flex-end;
          flex-direction: column;
          flex: 1 1 48%;

          input {
            padding: 8px 5px;
            border-radius: 5px;
            width: 100%;
          }

          label {
            max-width: 100%;
          }
        }

        .dropdownData {
          display: flex;
          flex-direction: column;
          // gap: 20px;
          flex: 1 1 40%;
          // padding: 50px 0px;
        }

        .radiodata {
          flex: 1 1 60%;
          display: flex;
          gap: 5px;
          align-items: center;

          input {
            max-width: 15px !important;
          }
        }
      }

      .btnContainer {
        width: 100%;
        justify-content: flex-end;
        display: flex;
        width: 100%;
        gap: 15px;
      }
    }
  }
}

.form-group {
  width: 50%;
}

@media screen and (max-width: 990px) {
  .invitePopupContainer {
    .invitePopupContainerInner {
      max-width: 75%;
      margin-left: 65px;
      margin: 0 auto;
    }
  }
}

@media screen and (max-width: 550px) {
  .invitePopupContainer {
    .invitePopupContainerInner {
      max-width: 75%;
      margin-left: 65px;
      margin: 0 auto;
      .uploadFileBody{
        .uploadFileRow1{
          flex-direction: column;
          .dropdownData{
          width: 100%;
          }
        }
      }
      .addChapterRow {
        gap: 20px;

        .userLogin {
          gap: 10px;

          .userLoginInner {
            flex-direction: column;
            gap: 10px;
            padding: 0;
          }
        }

        .inviteUserBtn {
          // flex-direction: column;
          justify-content: center;
          margin-top: 20px;
        }
      }
    }
  }
}