.manageAssessmentAdminContainer {
  .manageAssessmentHeader {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 5px 20px;
    p {
      color: var(--textColor);
      display: flex;
      gap: 20px;
      margin: 0;
    }
  }

  // .stepFourUl {
  //   font-weight: 600;
  //   list-style-type: none;
  //   list-style: none;
  //   padding-left: 0;
  //   color: var(--secondaryColor);
  //   border: 1px solid rgba(0, 0, 0, 0.2);
  //   padding: 20px;
  //   border-radius: 5px;
  //   flex: 0 1;
  //   // min-width: 280px;

  //   // max-height: 340px;
  //   // overflow-y: auto;
  //   svg {
  //     max-width: 16px;
  //   }
  //   ul {
  //     padding-left: 0;
  //     padding-bottom: 10px;
  //     color: var(--blackColor);

  //     ul {
  //       li {
  //       }
  //     }
  //     li {
  //       color: var(--textColor);

  //       ul {
  //         padding-bottom: 30px;

  //         padding-left: 15px;
  //         gap: 20px;
  //         li {
  //           color: #000;
  //         }
  //       }
  //     }
  //   }

  //   li {
  //     list-style-type: none;
  //   }
  // }
  .manageAssessmentBody {
    display: flex;
    flex-direction: column;
    gap: 20px;
    //  padding: 20px;
    // border: 1px solid rgba(0, 0, 0, 0.1);
    background-color: var(--whiteColor);

    .assessmentheader {
      display: flex;
      justify-content: space-between;
      gap: 10px;
      align-items: flex-end;
      flex-wrap: wrap;

      h4 {
        padding: 0px;
        flex-basis: 100%;
        font-size: calc(var(--primaryFont) + 4px);
        color: var(--primaryColor);
        font-weight: 600;
        align-items: center;
        // padding-top: 20px;
        // padding-bottom: 10px;
      }
      & > div {
        padding: 5px 10px;

        svg {
          max-width: 14px;
        }
      }
    }

    .assessmentBtnDiv {
      display: flex;
      justify-content: space-between;
      gap: 10px;
      align-items: center;
      h2 {
        font-size: 22px;
        font-weight: 600;
      }
      p {
        color: var(--textColor);
        display: flex;
        gap: 4px;
        font-size: var(--secondaryFont);
      }
    }

    .assessmentSelectDiv {
      display: flex;
      gap: 20px;
      align-items: center;

      & > div {
        max-width: 300px;
      }

      h3 {
        font-size: 16px;
      }
      .officeClass {
        max-width: 200px;
        & > ul {
          max-height: 36px;
        }
      }
    }
    .assessLocationCard {
      display: flex;
      gap: 15px;
      flex-direction: column;

      &:last-child {
        .locationCardDiv,
        .usCountry {
          .categoryCard {
            border-bottom: none;
          }
        }
      }

      h2 {
        color: var(--textColor);
        font-size: calc(var(--primaryFont) + 5px);
        font-weight: normal;
        // min-width: 12%;
        // max-width: 165px;
      }
      .regionHeading {
        // color: var(--secondaryColor);
        text-transform: uppercase;
        font-size: var(--secondaryFont);
        font-weight: 800;
      }
      .locationCardDiv {
        display: flex;
        gap: 10px;
        flex-direction: column;
        // align-items: center;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        padding: 0 20px;
        // border-radius: 5px;

        .categoryCard {
          display: flex;
          gap: 10px;
          align-items: flex-end;

          // border-top: 2px solid rgba(0, 0, 0, 0.1);
          //  border-bottom: 2px solid rgba(0, 0, 0, 0.1);
          padding-bottom: 15px;
        }
        h3 {
          // color: var(--textColor);
          font-weight: 600;
          // text-transform: uppercase;
          // font-size: calc(var(--secondaryFont) - 1px);
          font-size: var(--primaryFont);
          flex-basis: 80px;
        }
        .stateCard {
          h3 {
            color: var(--textColor);
            padding-bottom: 6px;
          }
        }
        .locationSpanDiv {
          display: flex;
          gap: 20px;
          a {
            text-decoration: none;
            line-height: initial;
            span {
              font-size: var(--secondaryFont);
              position: relative;
              color: var(--blackColor);
              text-transform: capitalize;
              border: 2px solid var(--secondaryColor);
              padding: 2px 8px;
              border-radius: 20px;

              // &:before {
              //   content: "|";
              //   position: absolute;
              //   right: -10px;
              //   cursor: auto;
              // }
            }
          }
          // &:last-child a span {
          //   &:before {
          //     display: none;
          //   }
          // }
        }
      }
      .usCountry {
        display: flex;
        gap: 10px;
        padding: 0 20px;
        flex-direction: column;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);

        .categoryCard {
          display: flex;
          gap: 10px;
          padding-bottom: 15px;
          flex-direction: column;
          flex-wrap: wrap;
        }
        h3 {
          font-weight: 600;
          font-size: var(--primaryFont);
          // flex-basis: 80px;
        }
        .stateCard {
          display: flex;
          gap: 20px;

          h3 {
            color: var(--textColor);
            padding-bottom: 6px;
            flex-basis: 60px;
          }
          .rightSpanAssessments {
            display: flex;
            gap: 10px;
            flex-wrap: wrap;
          }
        }
        .locationSpanDiv {
          display: flex;
          gap: 20px;
          a {
            text-decoration: none;
            line-height: initial;
            span {
              font-size: var(--secondaryFont);
              position: relative;
              color: var(--blackColor);
              text-transform: capitalize;
              border: 2px solid var(--secondaryColor);
              padding: 2px 8px;
              border-radius: 20px;
            }
          }
        }
      }
      .locationCardDivFull {
        flex-wrap: wrap;
        flex-direction: column;
        align-items: flex-start;
        h3 {
          flex-basis: 100%;
          width: 100%;
          max-width: none;
        }
      }
    }
  }
  .assessLocationBody {
    border: 2px solid rgba(0, 0, 0, 0.1);
    padding: 20px 20px 25px;
    border-radius: 5px;
    // margin-top: 20px;
  }
}
.assesmentLoader{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60vh;
}
