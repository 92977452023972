.manageAssessmentContainer {
  min-height: 100%;
  .manageAssessmentHeader {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 5px 20px;
    p {
      color: var(--textColor);
      display: flex;
      gap: 20px;
      margin: 0;
    }
  }
  .manageAssessmentBody {
    display: flex;
    flex-direction: column;
    gap: 20px;
    //  padding: 20px;
    // border: 1px solid rgba(0, 0, 0, 0.1);
    background-color: var(--whiteColor);
    margin-left: 2.7rem;
    margin-right: 2rem;

    .adminAssessmentTable {
      // overflow-x: auto;
      table {
        td {
          p {
            max-width: 200px;
            color: var(--textColor);
          }
          button {
            white-space: nowrap;
            svg {
              min-width: 12px;
            }
          }
        }
      }

      .noChapters {
        text-align: center;
        margin-top: 20px;
      }
    }
    .assessmentBtnDiv {
      display: flex;
      justify-content: flex-end;
    }
    .assessLocationCard {
      display: flex;
      gap: 15px;
      flex-direction: column;

      h2 {
        color: var(--textColor);
        font-weight: 600;
        text-transform: uppercase;
        font-size: calc(var(--secondaryFont) - 1px);
      }
      .locationCardDiv {
        display: flex;
        gap: 10px;
        align-items: center;
        h3 {
          color: var(--textColor);
          font-size: calc(var(--primaryFont) + 5px);
          min-width: 12%;
          max-width: 165px;
        }

        .locationSpanDiv {
          display: flex;
          gap: 20px;
          flex-grow: 1;
          a {
            text-decoration: none;
            span {
              font-size: var(--secondaryFont);
              position: relative;
              color: var(--blackColor);
              &:before {
                content: "|";
                position: absolute;
                right: -10px;
                cursor: auto;
              }
            }
          }
        }
      }
      .locationCardDivFull {
        flex-wrap: wrap;
        h3 {
          flex-basis: 100%;
          width: 100%;
          max-width: none;
        }
      }
    }
  }
  .topBtnContainer {
    display: flex;
    gap: 24px;
    margin-left: 2.7rem;
  }
  .BtnContainer{
    display: flex;
    justify-content: space-between;
    margin-right: 2rem;
  }
  .assessmentSubmittedMessage{
    margin-left: 2.7rem;
    color: #3e744d;
    margin-right: 2rem;
    padding: 7px;
    background-color: #d4edda;
  }
}

.submittedText {
  color: var(--primaryColor);
  text-align: center;
  font-weight: bold;
  margin-top: 10px !important;
}
