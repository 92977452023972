.checkbox {
  input {
    max-width: 10px;
    cursor: pointer;
  }
  label {
    cursor: pointer;
    font-size: 14px;
  }
}

.checkContainer {
  position: relative;
  padding-left: 25px;
  margin-bottom: 10px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: flex;
  align-items: center;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    left: 0;
    z-index: 1;
    height: 20px;
    width: 20px;
    max-width: none;
    bottom: 0;
    top: 0;
    margin: auto;
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #fff;
    bottom: 0;
    margin: auto;
    border: 2px solid var(--secondaryColor);
  }
  &:hover input ~ .checkmark {
    background-color: var(--backgroundColor);
  }

  input:checked ~ .checkmark {
    background-color: var(--secondaryColor);
  }
  input:checked ~ label {
    color: var(--secondaryColor);
  }

  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  input:checked ~ .checkmark:after {
    display: block;
  }

  .checkmark:after {
    left: 5px;
    top: 1px;
    width: 8px;
    height: 12px;
    border: solid var(--whiteColor);
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}
