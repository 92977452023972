.stepCommonInner {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  padding: 35px 0;
  max-width: 680px;
  // border: 1px solid var(--textColor);
  //border-radius: 5px;
  gap: 30px;
  flex: 1;

  .stepThreeHeading {
    display: flex;
    gap: 10px;
    justify-content: center;
    flex-direction: column;
    h3 {
      font-size: 1.5rem;
      font-weight: 600;
    }
    p {
      font-size: var(--secondaryFont);
      color: var(--textColor);
      padding-bottom: 20px;
    }
    // h3 {
    //   color: var(--textColor);
    //   font-weight: 600;
    //   flex: 1 1 30%;
    //   text-align: center;
    //   font-size: calc(var(--primaryFont) + 2px);
    // }
  }
  .stepThreeForm {
    display: flex;
    flex-direction: column;
    gap: 30px;
    width: 100%;
    .locationCard {
      display: flex;
      flex-direction: column;
      gap: 5px;
      border: 1px solid rgba(0, 0, 0, 0.1);
      padding: 20px 30px;
      border-radius: 5px;
      min-width: 300px;
      flex: 1 1 300px;
      .locationIconsInCard {
        display: flex;
        gap: 8px;
        position: absolute;
        right: 5px;
        top: 5px;
      }
      position: relative;
      & > button {
        position: absolute;
        right: 5px;
        top: 5px;
        &:hover {
          svg {
            fill: var(--redColor);
          }
        }
      }
    }
    .locations {
      text-align: left;
      display: flex;
      gap: 10px;
      // max-width: 500px;
      flex-wrap: wrap;
      justify-content: center;
      // margin: 0 auto;
      // gap: 5px;
      // border: 1px solid rgba(0, 0, 0, 0.1);
      // padding: 20px 30px;
      // border-radius: 5px;
      // max-width: 500px;
      // margin: 0 auto;
      &>p{
        flex-basis: 100%;
      }
    }
    .locationOnlyBtn {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      &>p{
        flex-basis: 100%;
      }
    }

    .locationBtn {
      display: flex;
      align-items: flex-end;
      position: relative;
      padding-top: 15px;
      height: auto;
      flex-basis: 100%;
      flex-direction: column;
      p {
        flex-basis: 100%;
        position: static;
        text-align: right;
      }
    }

    input {
      width: 100%;
      border-radius: 4px;
      padding: 10px;
      border: 1px solid var(--textColor);
    }
    .pageBtn {
      justify-content: right;
      display: flex;
      align-items: center;
      width: 100%;
      flex-wrap: wrap;
      gap: 10px;
      
    }
  }
}

@media screen and (max-width: 990px) {
  .stepCommonInner{
    .stepThreeForm{
      padding:0 20px;
    }
  }
}
