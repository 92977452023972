.manageAdminContainer {
    min-height: 100%;
    width: 100%;
 
   
  }

   
  .manageBreadCrumb{
    left: 0px;
    margin-bottom: 2rem;

}

.manageDropDown{
  display: flex;
  margin-bottom: 15px  ;
  gap:20px;
  
 
 

   
   &:first-child{
    // margin-right: 20px;

    p:first-of-type{
      color: red;
      font-size: 12px;
      padding: 0px 10px;
    }


   }

   .manageSearchbar{
    margin-left: auto;
   }
}
  .manageTableContainer{
    display: flex;
    flex-direction: column;
    justify-content: left;
    border: 1px solid #dfe2eb;
    padding: 20px 10px;
    border-radius: 5px;
    width: 100%;
    // overflow-x: scroll;

    p{
      text-align: center;
      padding: 10px;
    }
   

}
.orgUserHeader{
  width: 230px;
  .toastStateP{
    color: var(--redColor);
    font-size: 12px;
  }
}


