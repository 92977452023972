.dashboard {
  width: 100%;
}

.authData {
  // display: flex;
  // flex-direction: column;
  // align-items: flex-end;
  // flex: 1 1 50%;
  display: flex;
  justify-content: space-between;
  width: 100%;
  background-color: var(--mainBgColor);
  padding: 9px 8px 9px 9px;
  .loggedInUser {
    display: flex;
    flex-direction: column;
    justify-content: right;
    flex: 1 1 70%;

    .loggedInTime {
      text-align: "center";
      font-size: "14px";
    }
    .loggedInTimeLabel {
      font-weight: normal;
    }

    .loggedInUserContainer {
      display: flex;
      gap: 10px;
      border-radius: 10px;
      // align-items: center;
      width: 100%;
      // max-width: 180px;
      // max-width: 250px;
      position: relative;
      // padding: 10px 10px 20px;
      justify-content: flex-end;
      cursor: pointer;
      &:hover {
        .logoutUi {
          display: flex;
        }
      }
      .logoutUi {
        position: absolute;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        top: 95%;
        width: 100%;
        max-width: 180px;
        margin: 0 auto;
        transition: all 0.5s ease;
        display: none;
        background-color: var(--whiteColor);
        padding: 5px 5px;
        border-radius: 5px;
        -webkit-box-shadow: 0px 3px 4px 0px rgba(202, 202, 202, 1);
        -moz-box-shadow: 0px 3px 4px 0px rgba(202, 202, 202, 1);
        box-shadow: 0px 3px 4px 0px rgba(202, 202, 202, 1);
        a {
          color: var(--primaryColor);
          text-decoration: none;
        }
      }

      .userInitial {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        width: 100%;
        max-width: 40px;
        height: 40px;
        // flex: 1 1 50%;
        background-color: var(--secondaryColor);

        p {
          font-size: var(--primaryFont);
          color: var(--whiteColor);
          margin: 0;
          text-transform: uppercase;
          font-weight: bolder;
          padding: 5px;
        }
      }
      .userInfo {
        // flex: 1 1 50%;
        display: flex;
        flex-direction: column;

        p {
          margin: 0;
          &:nth-of-type(1) {
            font-weight: 600;
            font-size: 14px;

            color: var(--primaryColor);
          }
          &:nth-of-type(2) {
            font-size: 13px;
            color: var(--textColor);
          }
        }
      }
    }
  }
}

.breadcrumbcontainer {
  margin-bottom: 30px;
  margin-left: 10px;
}

.dashExecutiveContainer {
  display: flex;
  flex-direction: column;
  gap: 12px;
  // padding: 12px 20px 0px 20px;
  // height: 88%;
  width: 100%;

  .dashExecutiveBody {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px 0px;
    // height: 100%;
    border: 1px solid rgba(0, 0, 0, 0.1);
    background-color: var(--whiteColor);
    // flex: 1 1;

    h4 {
      font-weight: 600;
      text-transform: uppercase;
      text-align: center;
      font-size: var(--primaryFont);
      // color: var(--textColor);
      padding-bottom: 10px;
    }

    .dashExecutiveGraph {
      display: flex;
      // gap:20px;
      align-items: center;
      width: 100%;
      margin: 0px;
      overflow: hidden;

      canvas {
        // max-height: 300px !important;
        width: auto !important;
        max-width: 100% !important;
      }

      .dashExecGraphCircle {
        flex: 1 1 18%;
        padding-left: 10px;

        p {
          transform: translate(20%, -1000%);
        }
      }

      .dashExecGraphBar {
        flex: 1 1 50%;
        margin-right: 10px;

        p {
          transform: translate(30%, -1000%);
        }
      }
    }

    .dashExecGraphBarFull {
      width: 100%;
      padding-left: 10px;

      .dashExecArea {
        display: flex;
        gap: 20px;
        align-items: stretch;
        flex-wrap: wrap;

        // justify-content: space-between;
        .card {
          background-color: var(--whiteColor);
          border: 2px solid var(--tableBorderColor);
          border-radius: 5px;
          padding: 20px 15px;
          display: flex;
          justify-content: space-between;
          flex-direction: column;
          min-width: 15%;
          cursor: pointer;
          max-width: 250px;

          h3 {
            font-weight: 600;
            text-transform: uppercase;
            font-size: 14px;
            color: var(--textColor);
            padding-bottom: 10px;
          }

          .progressBarInCard {
            margin-top: auto;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1250px) {
  .dashExecutiveContainer {
    .dashExecutiveBody {
      .dashExecutiveGraph {
        flex-direction: column;
      }
    }
  }
}

@media screen and (max-width: 990px) {
  .dashExecutiveContainer {
    .dashExecutiveBody {
      .dashExecutiveGraph {
        flex-direction: column;

        .dashExecGraphBar {
          width: 100%;

          p {
            transform: translate(20%, -597%);
          }
        }
      }
    }
  }
}
