.invitePopupContainer {
  width: 100%;
  display: flex;
  justify-content: safe center;
  align-items: center;
  flex-direction: column;
  max-width: 600px;
  height: 100%;
  overflow-y: auto;
  // position: fixed;
  top: 10px;
  bottom: 10px;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 2;
  border: none !important;
  outline: none !important;


  // &:after {
  //   content: "";
  //   position: fixed;
  //   width: 100%;
  //   height: 100%;
  //   top: 0;
  //   left: 0;
  //   right: 0;
  //   bottom: 0;
  //   background-color: rgba(0, 0, 0, 0.5);
  //   background-position: center;
  //   background-repeat: no-repeat;
  // }
  .invitePopupContainerInner {
    // display: flex;
    // flex-direction: column;
    // justify-content: center;
    width: 100%;
    border-radius: 15px;
    max-height: 100%;
    height: auto;
    // max-width: 600px;
    // position: fixed;
    // top: 10px;
    // margin: auto;
    z-index: 2;
    // border-radius: 15px;
    // align-items: center;
    .invitePopupRow1 {
      width: 100%;
      background-color: var(--secondaryColor);
      color: var(--whiteColor);
      display: flex;
      padding: 15px 30px;
      justify-content: space-between;
      align-items: center;
      border-top-left-radius: 15px;
      border-top-right-radius: 15px;
    }
    .invitePopupRow2 {
      background-color: #fff;
      width: 100%;
      padding: 15px 30px;
      display: flex;
      border-bottom-left-radius: 15px;
      border-bottom-right-radius: 15px;
      flex-direction: column;
      gap: 65px;
      p {
        color: var(--textColor);
        font-weight: 600;
      }
      .userLogin {
        display: flex;
        gap:10px;
        flex-wrap: wrap;
        align-items: baseline;

        .inputBox {
          padding: 8px 5px;
          border-radius:4px;
          width:100%;
          padding:8px 5px;
          font-size:16px;
        }

        .userLoginInner {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          gap: 20px;
          .orgRegionsParent {
            display: flex;
            flex-direction: column;
            // margin: 30px 0px;

            h3 {
              padding-top: 10px;
              padding-bottom: 5px;
              font-size: var(--secondaryFont);
              font-weight: 600;
              color: var(--textColor);
              text-transform: uppercase;
            }

            .orgRegions {
              display: flex;
              justify-content: flex-start;
              //align-items: center;
              flex-wrap: wrap;
              gap: 10px;

              .regionBranch {
                text-align: left;
                padding: 20px 0 0 0;
                font-size: calc(var(--primaryFont) - 2px);
                font-weight: 600;
                color: var(--textColor);
                text-transform: uppercase;
                flex-basis: 100%;
              }

              .editField {
                display: flex;
                justify-content: space-between;
                flex-direction: column;
                align-items: flex-start;
                gap: 5px;
                flex-basis: 30%;
                position: relative;
                p {
                  position: absolute;
                  top: 100%;
                  left: 0;
                  color: var(--redColor);
                  font-size: 12px;
                  font-weight: normal;
                }
              }
              select {
                width: 100%;
                border: 1px solid var(--blackColor);
                border-radius: 5px;
                background-color: var(--whiteColor);
                height: 42px;
                padding: 10px 0 10px 10px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;

                &:focus {
                  outline: none;
                }
              }
            }
            button {
              height: fit-content;
              align-self: flex-end;
              margin-bottom: 10px;
            }
          }

          .regionAssessment {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            gap: 10px;
            align-items: flex-end;
            flex-basis: 1;
          }
          & > div {
            flex: 1 1 0;
          }

          label {
            font-size: var(--secondaryFont);
          }
        }
        .assessDropdown {
          display: flex;
          align-items: flex-start;
          gap: 10px;
          .selectUi {
            flex: 1 1;
          }
        }
        .userLoginInner.full {
          flex-basis: 100%;
          max-width: 100%;
        }
        .userLoginInner.fullInner {
          flex-wrap: wrap;
          gap: 10px;
          .locationHead {
            width: 100%;
            flex-basis: 100%;

            h4 {
              flex-basis: 100%;
              font-size: var(--secondaryFont);
              font-weight: 600;
              color: var(--textColor);
            }
          }
        }
      }
      .inviteUserBtn {
        display: flex;
        gap: 15px;
        justify-content: right;
        margin-top: 40px;
      }
    }
  }
}
.form-group {
  width: 50%;
}
@media screen and (max-width: 990px) {
  .invitePopupContainer {
    .invitePopupContainerInner {
      max-width: 75%;
      margin-left: 65px;
    }
  }
}
@media screen and (max-width: 550px) {
  .invitePopupContainer {
    .invitePopupContainerInner {
      max-width: 75%;
      top: 18%;
      .invitePopupRow2 {
        gap: 20px;
        .userLogin {
          gap: 10px;
          .userLoginInner {
            flex-direction: column;
            gap: 10px;
            padding: 0;

            .orgRegionsParent {
              .orgRegions {
                .editField {
                  flex: auto;
                }
              }
            }
          }
        }
        .inviteUserBtn {
          // flex-direction: column-reverse;
          justify-content: center;
          margin-top: 20px;
          flex-wrap: wrap;
          button{
            max-width: 100% !important;
          }
        }
      }
    }
  }
}
