.forgotStatus {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  width: 100%;
  .loginSiteLogo {
    align-self: flex-start;
    width: 100%;
  }

  .forgotStatusDiv {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    max-width: 400px;
    margin: auto;
    align-items: center;
    justify-content: center;
    flex: 1;
    h1 {
      font-size: 1.5rem;
      font-weight: 600;
    }
    p {
      font-size: var(--secondaryFont);
      color: var(--textColor);
      padding-bottom: 20px;
      span {
        display: block;
        text-align: center;
      }
    }
  }
  .formInnerBtnSection {
    display: flex;
    gap: 10px;
    width: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .resendSpan {
      display: flex;
      gap: 5px;
    }
  }
}

.toastReset {
  display: flex;
  align-items: center;
  justify-content: center;
}
.backtoLoginbtn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}
