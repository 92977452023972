.table {
  // background-color: var(--tableBorderColor);
  width: 100%;
  .tableHeading {
    text-align: left;
    background-color: var(--backgroundColor);
    // position: sticky;
    z-index: 1;
    top: -2px;
    th {
      color: var(--primaryColor);
      padding: 10px 10px;
      font-size: 12px;
      font-weight: 600;
      // min-width: 119px;
      min-width: fit-content;
      white-space: pre-wrap;
      border: 1px solid var(--tableBorderColor);
    }
  }
  .tableBody {
    background-color: #fff;
    tr {
      border-bottom: 1px solid var(--tableBorderColor);
      border-left: 1px solid var(--tableBorderColor);
      border-right: 1px solid var(--tableBorderColor);
      td {
        padding: 5px 10px;
        font-size: 12px;
        font-weight: normal;
        // max-width: 300px;
        // min-width: 200px;
        // overflow-wrap: break-word;

        a {
          font-size: 10px;
          font-weight: bold;
          color: var(--primaryColor);
          cursor: pointer;
          text-decoration: underline;
        }
        .actionBtn {
          display: flex;
          // justify-content: center;
          max-width: max-content;
          border-radius: 5px;
          gap: 10px;
          cursor: pointer;
          flex-direction: row !important;
          align-items: center;
        }
        .columnactionBtn {
          display: flex;
          // justify-content: center;
          max-width: max-content;
          border-radius: 5px;
          gap: 10px;
          cursor: pointer;
          flex-direction: column !important;
          align-items: center;
        }
      }
    }
  }
}
.actionTableHeading {
  min-width: 100px !important;
}
@media screen and (max-width:750px){
  .actionTableHeading{
    min-width: 100% !important;
  }
}
