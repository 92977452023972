.loginContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  min-height: 100%;
  flex-wrap: wrap;
  flex-direction: column;
  width: 100%;

  .loginFlexMain {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 60px;
    flex: 1;
    max-width: 90%;
    width: 100%;
    margin-top: 4rem;
  }

  .loginSiteLogo {
    // flex-basis: 100%;
    align-self: flex-start;
    // margin-bottom: auto;
  }
  .imageSection {
    max-width: 550px;
    // background-color: var(--secondaryColor);
    img {
      display: block;
      width: 100%;
      height: 100%;
    }
  }

  form {
    display: flex;
    flex-direction: column;
  }
  .loginForm {
    // background-color: #f5f5f5;
    text-align: center;
    width: 100%;
    max-width: 600px;
    min-width: 400px;
    .login {
      display: flex;
      flex-direction: row;
      height: 100%;
      .loginSection1 {
        flex: 1 1 50%;
        // background-color: var(--secondaryColor);

        width: 100%;

        .loginForm {
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0 auto;
          width: 100%;
          max-width: 100%;
          .loginFormInner {
            display: flex;
            flex-direction: column;
            // gap: 10px;
            width: 100%;
            max-width: 500px;
            .loginInputs {
              display: flex;
              flex-direction: column;
              gap: 10px;
            }
            .eyeIconPassword {
              position: absolute;
              right: 10px;
              max-width: 20px;
              top: 0;
              left: auto;
              bottom: 0;
              // margin: auto;
              margin: 10px 0px;
              cursor: pointer;
            }
            .loginHeaderImg {
              max-width: 55px;
              margin: 0 auto;
              background-color: var(--tableBorderColor);
              box-shadow: 0 0 0px 10px #e9ecf2;
              border-radius: 50%;
              padding: 15px;
            }
            p {
              font-size: var(--secondaryFont);
              color: var(--textColor);
              padding-bottom: 5px;
            }
            input {
              font-size: var(--secondaryFont);
              padding: 10px 7px;
              border-radius: 5px;
              width: 100%;
              border: 1px solid var(--blackColor);
              &:focus {
                border-color: var(--secondaryColor);
              }
            }
            h1 {
              font-size: 1.5rem;
              font-weight: 600;
            }
            .formInnerBtnSection {
              display: flex;
              flex-wrap: wrap;
              justify-content: center;
              margin-top: 10px;
              gap: 10px;
            }
            .requirements {
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              text-align: left;
              font-size: var(--secondaryFont);
              margin-top: 20px;

              h4 {
                font-size: var(--primaryFont);
                color: var(--primaryColor);
                font-weight: 600;
              }
              .fulfilled {
                color: #1e9e1e;
              }
            }
          }
        }
      }
    }
  }
  .loginButton{
    width: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
  }
}

@media screen and (max-width: 990px) {
  .loginContainer {
    width: 100%;
    .loginForm {
      height: 100%;
      padding: 0 20px;
      .login {
        .loginSection1 {
          .loginForm {
            .loginFormInner {
              padding-bottom: 50px;
            }
          }
        }
      }
    }
    .imageSection {
      display: none;
    }
  }
}
@media screen and (max-width: 550px) {
  .loginContainer {
    width: 100%;
    .loginForm {
      height: 100%;
      padding: 0;
      min-width: 100% !important;
      .login {
        .loginSection1 {
          .loginForm {
            align-items: flex-start;
            padding: 20px;
          }
        }
      }
    }
    .imageSection {
      display: none;
    }
  }
  .loginFlexMain{
    min-width: 100% !important;
  }
  .loginButton {
    width: 100%;
    button{
      width: 100% !important;
    }
}
}

    
  

