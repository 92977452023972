.invitePopupContainer {
  width: 100%;
  display: flex;
  justify-content: safe center;
  align-items: center;
  flex-direction: column;
  max-width: 600px;
  height: 98%;
  overflow-y: auto;
  // position: fixed;
  // top: 10px;
  // bottom: 10px;
  // left: 0;
  // right: 0;
  margin: auto;
  z-index: 2;
  border: none !important;
  outline: none !important;


  // &:after {
  //   content: "";
  //   position: fixed;
  //   width: 100%;
  //   height: 100%;
  //   top: 0;
  //   left: 0;
  //   right: 0;
  //   bottom: 0;
  //   background-color: rgba(0, 0, 0, 0.5);
  //   background-position: center;
  //   background-repeat: no-repeat;
  // }
  .invitePopupContainerInner {
    // display: flex;
    // flex-direction: column;
    // justify-content: center;
    width: 100%;
    // height: 100%;
    z-index: 2;
    border-radius: 15px;
    .addChapterHeader {
      width: 100%;
      border-top-left-radius: 15px;
      border-top-right-radius: 15px;
      background-color: var(--secondaryColor);
      color: var(--whiteColor);
      display: flex;
      padding: 15px 30px;
      justify-content: space-between;
      align-items: center;
    }
    .addChapterRow {
      background-color: #fff;
      width: 100%;
      padding: 15px 30px;
      display: flex;
      flex-direction: column;
      border-bottom-left-radius: 15px;
      border-bottom-right-radius: 15px;
      gap: 65px;
      p {
        font-weight: 600;
      }
      .userLogin {
        display: flex;
        gap: 20px 10px;
        flex-direction: column;

        .userLoginInner {
          display: flex;
          align-items: center;
          justify-content: center;

          input {
            padding: 10px 5px;
            border-radius: 5px;
            width: 100%;
          }
          label {
            font-size: var(--primaryFont);
          }
        }
        .userLoginInner.full {
          flex-basis: 100%;
        }
      }
      .inviteUserBtn {
        display: flex;
        gap: 15px;
        justify-content: right;
        margin-top: 40px;
      }
    }
  }
}
.form-group {
  width: 50%;
}
@media screen and (max-width: 990px) {
  .invitePopupContainer {
    .invitePopupContainerInner {
      max-width: 75%;
      margin-left: 65px;
    }
  }
}
@media screen and (max-width: 550px) {
  .invitePopupContainer {
    .invitePopupContainerInner {
      max-width: 75%;
      margin-left: 65px;
      top: 18%;
      .addChapterRow {
        gap: 20px;
        .userLogin {
          gap: 10px;
          .userLoginInner {
            flex-direction: column;
            gap: 10px;
            padding: 0;
          }
        }
        .inviteUserBtn {
          // flex-direction: column;
          justify-content: center;
          margin-top: 20px;
        }
      }
    }
  }
}
