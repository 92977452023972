.invitePopupContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  // align-items: center;
  flex-direction: column;
  max-width: 600px;
  // height: 98%;
  // overflow-y: auto;
  // position: fixed;
  // top: 20px;
  // bottom: 10px;
  // left: 0;
  // right: 0;
  height: 100%;
  margin: auto;
  z-index: 2;
  border: none !important;
  outline: none !important;

  // &:after {
  //   content: "";
  //   position: fixed;
  //   width: 100%;
  //   height: 100%;
  //   top: 0;
  //   left: 0;
  //   right: 0;
  //   bottom: 0;
  //   background-color: rgba(0, 0, 0, 0.5);
  //   background-position: center;
  //   background-repeat: no-repeat;
  // }
  .invitePopupContainerInner {
    width: 100%;
    max-height: 80%;
    border-radius: 15px;
    min-height: auto;
    z-index: 2;

    .addChapterHeader {
      width: 100%;
      background-color: var(--secondaryColor);
      color: var(--whiteColor);
      display: flex;
      padding: 15px 30px;
      justify-content: space-between;
      align-items: center;
      border-top-left-radius: 15px;
      border-top-right-radius: 15px;
    }
    .viewChapterBody {
      width: 100%;
      padding: 20px 30px 30px;
      display: flex;
      flex-direction: column;
      gap: 15px;
      background-color: var(--whiteColor);
      border-bottom-left-radius: 15px;
      border-bottom-right-radius: 15px;
      max-height: 90%;
      overflow: auto;

      .chapterItem {
        display: flex;
        gap: 0px;
        flex-direction: column;

        label {
          font-weight: 600;
        }
      }
    }
  }
}
