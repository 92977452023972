.formGroup {
  width: 100%;
  // padding-bottom: 5px;
  label {
    max-width: 80%;
    line-height: normal;
    text-align: left;
    width: 100%;
    font-weight: normal;
  }
  textarea {
    border: 1px solid var(--blackColor);
    position: relative;
  }
  .svg_image_icon {
    position: absolute;
    left: 8px;
    top: 4px;
    z-index: 1;
    width: auto;
    height: auto;
    svg {
      max-width: 12px;
    }
  }

  .withLogo {
    padding-left: 25px !important;
  }
}
