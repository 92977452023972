.sideBar {
  background-color: var(--mainBgColor);
  height: 100%;
  .sideBarInner {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 13px 5px;
    height: 100%;
    overflow-y: auto;

    .logoutButton {
      margin-top: auto;
    }

    .dashBoardList {
      ul {
        display: flex;
        flex-direction: column;
        list-style-type: none;
        padding: 0;
        margin: 0;
        gap: 25px;

        li {
          display: flex;
          justify-content: center;
          flex-direction: column;
          font-size: var(--secondaryFont);
          padding-bottom: 5px;
          gap: 5px;
          // border-radius: 10px;
          // border-bottom: 1px solid rgba(0, 0, 0, 0.1);
          // border-top: 1px solid rgba(0, 0, 0, 0.10);
          span {
            // padding: 15px 15px 5px;
            padding: 15px 0px 10px;
            font-weight: 600;
            text-transform: uppercase;
            font-size: smaller;
            cursor: pointer;
            width: 100%;
            justify-content: space-between;
            display: flex;
          }
          a {
            text-decoration: none;
            font-weight: 600;
            color: var(--primaryColor);
            // font-size: 16px;
            font-size: 14px;
            display: flex;
            padding: 10px;
            gap: 6px;
            // border-radius: 10px;
            border-radius: 4px;
            align-items: center;
            &:hover {
              background-color: var(--tableBorderColor);
              cursor: pointer;
              // border-radius: 10px;
              border-radius: 4px;
              // color: var(--whiteColor);
              color: var(--primaryColor);
              svg {
                fill: var(--primaryColor) !important;
              }
            }
            svg {
              width: max-content;
              min-width: 15px;
            }
          }
          div {
            text-decoration: none;
            font-weight: 600;
            color: var(--primaryColor);
            font-size: 14px;
            display: flex;
            padding: 10px 15px;
            gap: 10px;
            border-radius: 4px;
            align-items: center;
            &:hover {
              background-color: var(--tableBorderColor);
              cursor: pointer;
              border-radius: 4px;
              color: var(--primaryColor);
              // svg {
              //   fill: var(--primaryColor) !important;
              // }
            }
            svg {
              width: max-content;
              min-width: 15px;
            }
          }
          .active {
            // background-color: var(--secondaryColor);
            background-color: var(--lightGrey);
            // color: var(--whiteColor);
            color: var(--primaryColor);
          }
          img {
            max-width: 20px;
            margin-right: 8px;
          }
          span {
            color: var(--textColor);
            &:hover {
              color: var(--secondaryColor);
            }
          }
        }
      }
      .headerLogo {
        display: flex;
        padding: 0 0 35px 0;
        align-items: center;
        gap: 10px;
        justify-content: center;
        // p {
        //   color: var(--secondaryColor);
        //   font-size: var(--logoFont);
        //   font-weight: 900;
        //   cursor: pointer;
        //   margin: 0;
        // }
        img {
          max-width: 160px;
        }
        svg {
          display: none;
        }
      }
      .loggedInUser {
        display: flex;
        flex-direction: column;
        justify-content: right;
        flex: 1 1 70%;

        .loggedInTime {
          text-align: "center";
          font-size: "14px";
        }
        .loggedInTimeLabel {
          font-weight: normal;
        }

        .loggedInUserContainer {
          display: flex;
          gap: 10px;
          border-radius: 10px;
          // align-items: center;
          width: 100%;
          // max-width: 180px;
          max-width: 250px;
          position: relative;
          padding: 10px 10px 20px;
          cursor: pointer;
          &:hover {
            .logoutUi {
              display: flex;
            }
          }
          .logoutUi {
            position: absolute;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            top: 95%;
            width: 100%;
            max-width: 180px;
            margin: 0 auto;
            transition: all 0.5s ease;
            display: none;
            background-color: var(--whiteColor);
            padding: 5px 5px;
            border-radius: 5px;
            -webkit-box-shadow: 0px 3px 4px 0px rgba(202, 202, 202, 1);
            -moz-box-shadow: 0px 3px 4px 0px rgba(202, 202, 202, 1);
            box-shadow: 0px 3px 4px 0px rgba(202, 202, 202, 1);
            a {
              color: var(--primaryColor);
              text-decoration: none;
            }
          }

          .userInitial {
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            width: 100%;
            max-width: 35px;
            height: 35px;
            flex: 1 1 50%;
            background-color: var(--secondaryColor);

            p {
              font-size: var(--primaryFont);
              color: var(--whiteColor);
              margin: 0;
              text-transform: uppercase;
              font-weight: bolder;
              padding: 5px;
            }
          }
          .userInfo {
            flex: 1 1 50%;
            display: flex;
            flex-direction: column;

            p {
              margin: 0;
              &:nth-of-type(1) {
                font-weight: 600;
                // font-size: var(--primaryFont);
                font-size: 14px;
              }
              &:nth-of-type(2) {
                // font-size: var(--secondaryFont);
                font-size: 13px;
                color: var(--textColor);
              }
            }
          }
        }
      }
    }
  }
}
.arrow {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: "center";
  width: 100%;
  padding: 10px 5px !important;
  &:hover {
    p {
      color: var(--secondaryColor);
    }
    span {
      color: var(--secondaryColor) !important;
    }
  }
  p {
    color: var(--secondaryColor);
    font-weight: 700;
    font-size: 15px;
    gap: 10px;
    // text-transform: lowercase;
    // font-size: smaller;
  }
  .section {
    display: flex;
    align-items: center;
  }
}

// @media screen and (max-width: 990px) {
.sideBar {
  z-index: 99;

  .sideBarInner {
    .dashBoardList {
      .headerLogo {
        width: 100%;
        display: flex;
        align-items: center;
        gap: 12px;
        svg {
          display: block;
          flex: 1 1 20%;
        }
        img {
          flex: 1 1 80%;
        }
      }
    }
  }
}
// }
