// .header {
//   width: 100%;
//   max-height: 70px;
//   background-color: var(--primaryColor);
//   flex: 0;
//   position: fixed;
//   top: 0;
//   z-index: 2;
//   .headerContainer {
//     display: flex;
//     justify-content: space-between;
//     align-items: center;
//     padding: 0px 30px;
//     margin: 0 auto;
//     .headerLogo {
//       display: flex;
//       padding: 15px 0;
//       align-items: center;
//       flex: 1 1 30%;
//       p {
//         color: var(--secondaryColor);
//         font-size: var(--logoFont);
//         font-weight: 900;
//         cursor: pointer;
//         margin: 0;
//       }
//       img {
//         max-width: 160px;
//       }
//     }
//     .loggedInUser {
//       display: flex;
//       justify-content: right;
//       flex: 1 1 70%;

//       .loggedInUserContainer {
//         display: flex;
//         gap: 10px;
//         border-radius: 10px;
//         align-items: center;
//         width: 100%;
//         justify-content: right;
//         // max-width: 180px;
//         max-width: 250px;
//         position: relative;
//         padding: 10px;
//         cursor: pointer;
//         &:hover {
//           .logoutUi {
//             display: flex;
//           }
//         }
//         .logoutUi {
//           position: absolute;
//           align-items: center;
//           justify-content: center;
//           flex-direction: column;
//           top: 95%;
//           width: 100%;
//           max-width: 180px;
//           margin: 0 auto;
//           transition: all 0.5s ease;
//           display: none;
//           background-color: var(--whiteColor);
//           padding: 5px 5px;
//           border-radius: 5px;
//           -webkit-box-shadow: 0px 3px 4px 0px rgba(202, 202, 202, 1);
//           -moz-box-shadow: 0px 3px 4px 0px rgba(202, 202, 202, 1);
//           box-shadow: 0px 3px 4px 0px rgba(202, 202, 202, 1);
//           a {
//             color: var(--primaryColor);
//             text-decoration: none;
//           }
//         }
//         .userInitial {
//           display: flex;
//           align-items: center;
//           justify-content: center;
//           border-radius: 50%;
//           width: 100%;
//           max-width: 35px;
//           height: 35px;
//           flex: 1 1 50%;
//           background-color: var(--secondaryColor);

//           p {
//             color: var(--whiteColor);
//             font-size: var(--primaryFont);
//             margin: 0;
//             text-transform: uppercase;
//             font-weight: bolder;
//             padding: 5px;
//           }
//         }
//         .userInfo {
//           flex: 1 1 50%;
//           display: flex;
//           flex-direction: column;
//           max-width: fit-content;

//           p {
//             margin: 0;
//             &:nth-of-type(1) {
//               font-weight: 600;
//               // font-size: var(--primaryFont);
//               font-size: 14px;
//               color: var(--whiteColor);
//             }
//             &:nth-of-type(2) {
//               // font-size: var(--secondaryFont);
//               font-size: 13px;
//               // color: var(--textColor);
//               color: var(--whiteColor);
//             }
//           }
//         }
//       }
//     }
//   }
// }
// .mobileViewSideBar {
//   width: 100%;
//   height: 100%;
//   position: fixed;
//   top: 68px;
// }
// @media screen and (max-width: 990px) {
//   .header {
//    .headerContainer{
//     padding: 0 10px;
//    width: 100%;

//    }
//   }
// }
// @media screen and (max-width: 550px) {
//   .header {
//     .headerContainer{
//      padding: 0 10px;
//     width: 100%;
//     .loggedInUser{
//      .loggedInUserContainer{
//        display: none;
//      }
//     }
//     }
//    }
// }

.authData {
  // display: flex;
  // flex-direction: column;
  // align-items: flex-end;
  // flex: 1 1 50%;
  // display: flex;
  // justify-content: center;
  // align-items: center;
  // width: 100%;
  position: sticky;
  top: 0;
  z-index: 10;
  background-color: var(--mainBgColor);
  padding: 8px 15px 8px 15px;
  .breadcrumb {
    width: 100%;
    font-size: small;
    flex: 1 1 33%;
  }
  .orgName {
    flex: 1 1 33%;
    div{
      div{
        justify-content: center !important;
      }
    }
  }
  .loggedInUser {
    display: flex;
    flex-direction: column;
    justify-content: right;
    flex: 1 1 33%;

    .loggedInTime {
      text-align: "center";
      font-size: "14px";
    }
    .loggedInTimeLabel {
      font-weight: normal;
    }

    .loggedInUserContainer {
      display: flex;
      gap: 10px;
      border-radius: 10px;
      // align-items: center;
      width: 100%;
      // max-width: 180px;
      // max-width: 250px;
      position: relative;
      // padding: 10px 10px 20px;
      justify-content: flex-end;
      cursor: pointer;
      &:hover {
        .logoutUi {
          display: flex;
        }
      }
      .logoutUi {
        position: absolute;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        top: 95%;
        width: 100%;
        max-width: 180px;
        margin: 0 auto;
        transition: all 0.5s ease;
        display: none;
        background-color: var(--whiteColor);
        padding: 5px 5px;
        border-radius: 5px;
        -webkit-box-shadow: 0px 3px 4px 0px rgba(202, 202, 202, 1);
        -moz-box-shadow: 0px 3px 4px 0px rgba(202, 202, 202, 1);
        box-shadow: 0px 3px 4px 0px rgba(202, 202, 202, 1);
        a {
          color: var(--primaryColor);
          text-decoration: none;
        }
      }

      .userInitial {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        width: 100%;
        max-width: 40px;
        height: 40px;
        // flex: 1 1 50%;
        background-color: var(--secondaryColor);

        p {
          font-size: var(--primaryFont);
          color: var(--whiteColor);
          margin: 0;
          text-transform: uppercase;
          font-weight: bolder;
          padding: 5px;
        }
      }
      .userInfo {
        // flex: 1 1 50%;
        display: flex;
        flex-direction: column;

        p {
          margin: 0;
          &:nth-of-type(1) {
            font-weight: 600;
            font-size: small;

            color: var(--primaryColor);
          }
          &:nth-of-type(2) {
            font-size: 13px;
            color: var(--textColor);
          }
        }
      }
    }
  }
}
