.invitePopupContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  // align-items: center;
  flex-direction: column;
  max-width: 600px;
  height: 98%;
  overflow-y: auto;
  align-items: center;
  // position: fixed;
  // top: 10px;
  // bottom: 10px;
  // left: 0;
  // right: 0;
  margin: auto;
  z-index: 2;
  border-radius: 4px;
  border: none !important;
  outline: none !important;

  // &:after {
  //   content: "";
  //   position: fixed;
  //   width: 100%;
  //   height: 100%;
  //   top: 0;
  //   left: 0;
  //   right: 0;
  //   bottom: 0;
  //   background-color: rgba(0, 0, 0, 0.5);
  //   background-position: center;
  //   background-repeat: no-repeat;
  // }
  .invitePopupContainerInner {
    // display: flex;
    // flex-direction: column;
    // justify-content: center;
    width: 100%;
    // height: 100%;
    // max-width: 600px;
    // position: fixed;
    // top: 10px;
    // margin: auto;
    z-index: 2;
    border-radius: 15px;
    // align-items: center;
    background-color: #fff;
    .addChapterHeader {
      width: 100%;
      background-color: var(--secondaryColor);
      color: var(--whiteColor);
      display: flex;
      padding: 15px 30px;
      justify-content: space-between;
      border-top-left-radius: 15px;
      border-top-right-radius: 15px;
      align-items: center;
    }
    // .invitePopupRow1 {
    //   width: 100%;
    //   background-color: var(--secondaryColor);
    //   color: var(--whiteColor);
    //   display: flex;
    //   padding: 15px 30px;
    //   justify-content: space-between;
    //   align-items: center;
    // }
    // .invitePopupRow2 {
    //   background-color: #fff;
    //   width: 100%;
    //   padding: 15px 30px;
    //   display: flex;
    //   flex-direction: column;
    //   gap: 65px;
    //   p {
    //     color: var(--textColor);
    //     font-weight: 600;
    //   }

    // }
    .userLogin {
      display: flex;
      gap: 15px 10px;
      flex-wrap: wrap;
      padding: 15px 30px;

      .userLoginInner {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-basis: 48%;

        input {
          padding: 10px 5px;
          border-radius: 4px;
          width: 100%;
        }
        label {
          font-size: var(--primaryFont);
        }
      }
      .userLoginInner.full {
        flex-basis: 100%;
      }
      .fullInner {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        gap: 20px;
        flex-basis: 100%;

        .orgRegionsParent {
          display: flex;
          flex-direction: column;
          width: 100%;
          // margin: 30px 0px;

          h3 {
            padding-top: 10px;
            padding-bottom: 5px;
            text-align: left;
            font-size: var(--secondaryFont);
            font-weight: 600;
            // color: var(--textColor);
            text-transform: uppercase;
          }

          .orgRegions {
            display: flex;
            justify-content: flex-start;
            //align-items: center;
            flex-wrap: wrap;
            gap: 10px;

            .regionBranch {
              text-align: left;
              padding: 20px 0 0 0;
              font-size: calc(var(--primaryFont) - 2px);
              font-weight: 600;
              color: var(--textColor);
              text-transform: uppercase;
              flex-basis: 100%;
            }

            .editField {
              display: flex;
              justify-content: space-between;
              flex-direction: column;
              align-items: flex-start;
              gap: 5px;
              flex-basis: 31.5%;
              position: relative;
              p {
                position: absolute;
                top: 100%;
                left: 0;
                color: var(--redColor);
                width: 100%;
                font-size: 12px;
              }
            }
            select {
              width: 100%;
              border: 1px solid var(--blackColor);
              border-radius: 4px;
              background-color: var(--whiteColor);
              height: 42px;
              padding: 6px 0 6px 5px;
              white-space: nowrap;
              // overflow: hidden;
              cursor: pointer;
              text-overflow: ellipsis;
              // color: var(--textColor);

              &:focus {
                outline: none;
              }
            }
          }
          button {
            height: fit-content;
            align-self: flex-end;
            margin-bottom: 10px;
          }
        }
      }
    }

    .inviteUserBtn {
      display: flex;
      gap: 15px;
      justify-content: right;
      // margin-top: 40px;
      padding: 15px 30px;
    }
  }
}
.form-group {
  width: 50%;
}
#deleteChapterbtn svg {
  fill: #ffffff !important;
  g {
    fill: #ffffff !important;
  }
}

.errorPublish {
  text-align: center;
  margin: auto;
  color: red;
  font-size: 12px;
  margin-bottom: 1rem;
}

@media screen and (max-width: 990px) {
  .invitePopupContainer {
    .invitePopupContainerInner {
      max-width: 75%;
      margin-left: 65px;
    }
  }
}
@media screen and (max-width: 550px) {
  .invitePopupContainer {
    .invitePopupContainerInner {
      max-width: 300px;   max-width: 75%;
      margin-left: 65px;  top: 18%;
      .invitePopupRow2 {
        gap: 20px;
        .userLogin {
          gap: 10px;
          .userLoginInner {
            flex-direction: column;
            gap: 10px;
            padding: 0;
          }
        }
        .inviteUserBtn {
          // flex-direction: column;
          justify-content: center;
          margin-top: 20px;
          flex-wrap: wrap;
          button{
            max-width: 100% !important;
          }
        }
      }
    }
  }
}
