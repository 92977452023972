.stepperMainDiv {
  display: flex;
  align-items: center;
  justify-content: right;
  position: relative;
  // flex: 1 1;
  width: 100%;
  // max-width: 80%;
  // margin: auto;

  .stepCommon {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 15px 0px;
    flex-direction: column;
    height: 100%;
    gap: 15px;
    flex-basis: calc(100% - 300px);
  }
}

.stepperBody {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  min-height: 100%;
  flex-wrap: wrap;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin: auto;

  // display: flex;
  // flex-direction: column;
  // gap: 0px;
  // align-items: center;
  // justify-content: center;
  // flex: 1;

  // display: flex;
  // align-items: center;
  // justify-content: center;

  // flex: 1 1;
  // width: 100%;
  h1 {
    font-weight: 600;
    font-size: calc(var(--primaryFont) + 12px);
  }
  .stepperForm {
    // width: 550px;
    // max-width: 100%;

    // display: flex;
    gap: 20px;
    // justify-content: center;
    // align-items: center;
    // width: 100%;
    // max-width: 900px;
    // margin: auto;

    text-align: center;
    width: 100%;
    flex: 1;
    display: flex;
    align-items: center;
    flex-direction: column;
    .stepperMainDiv {
      display: flex;
      align-items: center;
      justify-content: right;

      flex: 1 1;
      width: 100%;
      // max-width: 80%;
      // margin: auto;
      svg {
        max-width: 14px;
      }

      .stepFour {
        width: 100%;
        // height: 711px;
        // background-color: var(--backgroundColor);
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        padding: 15px 40px;
        flex-direction: column;
        flex: 1;
        height: 100%;

        .stepFourInner {
          display: flex;
          flex-direction: column;
          justify-content: center;
          width: 100%;
          padding: 35px;

          max-width: 680px;
          // border: 1px solid var(--textColor);
          //border-radius: 5px;
          gap: 30px;
          flex: 1;

          .stepFourHeading {
            display: flex;
            gap: 10px;
            justify-content: center;
            flex-direction: column;
            h3 {
              // color: var(--textColor);
              // font-size: var(--primaryFont);
              // font-weight: bold;

              font-size: 1.5rem;
              font-weight: 600;
            }
            p {
              font-size: var(--secondaryFont);
              color: var(--textColor);
              padding-bottom: 20px;
            }
            // h3 {
            //   color: var(--textColor);
            //   font-weight: 600;
            //   flex: 1 1 30%;
            //   text-align: center;
            //   font-size: calc(var(--primaryFont) + 2px);
            // }
          }
          .stepFourForm {
            display: flex;
            text-align: left;
            flex-direction: column;
            gap: 30px;
            width: 100%;
            .stepFourUl {
              font-weight: 600;
              list-style-type: none;
              list-style: none;
              padding-left: 0;
              ul {
                padding-left: 0;
                padding-bottom: 10px;
                li {
                  color: var(--textColor);

                  ul {
                    padding-bottom: 30px;
                    display: flex;
                    padding-left: 5px;
                    gap: 20px;
                    li {
                      color: #000;
                    }
                  }
                }
              }

              li {
                list-style-type: none;
              }
            }
            input {
              width: 100%;
              border-radius: 4px;
              padding: 10px;
              border: 1px solid var(--textColor);
            }
          }
        }
      }
    }
  }
}

.stepperSuccessBody {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin: auto;

  .stepSuccessDiv {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    gap: 10px;
    svg {
      height: auto;
    }
    h3 {
      font-size: 1.5rem;
      font-weight: 600;
    }
    p {
      font-size: 1.6rem;
      color: var(--greenColor);
      padding-bottom: 20px;
      font-weight: 700;
    }
    img {
      max-width: 360px;
    }
  }
}

@media screen and (max-width: 991px) {
  .stepperMainDiv {
    flex-wrap: wrap;

    .stepCommon {
      flex-basis: 100%;
    }
  }
  .stepperBody{
    height: auto;
  }
}
