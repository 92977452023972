.invitePopupContainer {
  width: 100%;
  display: flex;
  justify-content: safe center;
  align-items: center;
  flex-direction: column;
  max-width: 50%;
  height: 100%;
  margin-left: 30%;
  // overflow-y: auto;
  // position: fixed;
  top: 0px;
  bottom: 0px;
  // left: 30% !important;
  right: 0;
  cursor: auto;
  //margin: auto;
  z-index: 2;
  border: none !important;
  outline: none !important;

  // &:after {
  //   content: "";
  //   cursor: auto;
  //   position: fixed;
  //   width: 100%;
  //   height: 100%;
  //   top: 0;
  //   left: 0;
  //   right: 0;
  //   bottom: 0;
  //   background-color: rgba(0, 0, 0, 0.5);
  //   background-position: center;
  //   background-repeat: no-repeat;
  // }
  .invitePopupContainerInner {
    width: 100%;
    height: 80%;
    z-index: 2;
    margin-top: 15px;

    .uploadFileHeader {
      width: 100%;
      display: flex;
      border-radius: 15px 15px 0 0;
      padding: 15px 30px 10px;
      justify-content: space-between;
      align-items: center;
      background-color: var(--secondaryColor);

      h2 {
        color: var(--whiteColor);
        font-size: 22px;
        font-weight: 700;
      }
      svg {
        fill: var(--whiteColor);
        max-width: 16px;
        cursor: pointer;
      }
    }
    .uploadFileBody {
      padding: 15px 30px;
      border-radius: 0 0 15px 15px;
      display: flex;
      background-color: var(--whiteColor);
      flex-direction: column;
      gap: 25px;
      min-height: 90.5%;
      overflow: auto;
      max-height: 90.5%;
      .withBorder {
        padding-bottom: 25px;
        border-bottom: 2px solid #e9e9e9;
      }
      .assignmentDetailCard {
        h3 {
          color: var(--textColor);
          font-size: calc(var(--secondaryFont) - 2px);
        }
        h2 {
          font-size: var(--primaryFont);
          padding-top: 5px;
        }
        .uploadedDocAssign {
          display: flex;
          gap: 10px;
          align-items: center;
          .uploadedFileName{
            max-width: 60%;
            word-wrap: break-word;
          }

          svg:last-child {
            margin-left: auto;
          }
        }
        svg {
          max-height: 14px;
          width: auto;
        }
      }
      .assessmentStatus{
        color: rgb(9, 178, 54);
        text-transform: uppercase;
      }
      .attachFileFlex {
        display: flex;
        justify-content: space-between;
        align-items: center;

        & > div {
          // margin-top: 10px;
          // max-width: 210px;
        }
      }
      .assignmentDetails {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(49%, 1fr));
        gap: 25px 10px;
      }
      .taskDetailLog {
        display: flex;
        justify-content: flex-end;
        position: relative;
      }

      .commentSectionTaskDetail {
        width: 100%;
        position: relative;
        label {
          color: var(--textColor);
          font-size: calc(var(--secondaryFont) - 2px);
        }
        textarea {
          width: 100%;
          min-height: 45px;
          border: none;
          border: 2px solid var(--tableBorderColor);
          // border-bottom: 2px solid var(--tableBorderColor);
          // font-size: calc(var(--secondaryFont) - 3px);
          padding-left: 0;
          padding-right: 0;
          line-height: normal;
        }
        // .inputUserIcon {
        //   position: absolute;
        //   left: 0;
        //   z-index: 1;
        //   height: 100%;
        //   display: flex;
        //   bottom: 13px;
        //   max-width: 18px;
        //   align-items: center;
        // }
        .attachmentIcons {
          position: absolute;
          right: 0;
          top: 0;
          z-index: 1;
          height: 100%;
          display: flex;
          align-items: center;
          gap: 10px;
        }
        svg {
          max-height: 18px;
          width: 100%;
        }
      }

      .viewComments {
        display: flex;
        align-items: flex-start;
        // justify-content: space-between;
        gap: 10px;
        .viewCommentsUser {
          display: flex;
          flex-direction: column;
          h5 {
            font-size: var(--secondaryFont);
            font-weight: 600;
          }
          p {
            font-size: var(--secondaryFont);
          }
        }
        span {
          color: var(--textColor);
          font-size: calc(var(--secondaryFont) - 2px);
          margin-left: auto;
        }
        svg {
          margin-top: 2px;
        }
      }
      .uploadedDocAssign {
        span {
          color: var(--textColor);
          font-size: calc(var(--secondaryFont) - 2px);
          margin-left: auto;
        }
      }

      // .uploadFileRow1 {
      //   display: flex;
      //   width: 100%;
      //   justify-content: space-between;
      //   align-items: center;
      //   gap: 20px;
      //   .dropdownData {
      //     display: flex;
      //     flex-direction: column;
      //     gap: 20px;
      //     flex: 1 1 40%;
      //     // padding: 50px 0px;
      //   }
      //   .radiodata {
      //     flex: 1 1 60%;
      //     display: flex;
      //     gap: 5px;
      //     align-items: center;
      //     input {
      //       max-width: 15px !important;
      //     }
      //   }
      // }
      // .uploadFileRow2 {
      //   display: flex;
      //   flex-direction: column;
      //   justify-content: space-between;
      //   gap: 20px;
      //   width: 100%;
      //   .documentList {
      //     display: flex;
      //     justify-content: space-between;
      //     align-items: center;
      //     max-height: 165px;
      //     overflow: auto;
      //     p {
      //       color: var(--textColor);
      //       font-size: medium;
      //       &:last-of-type {
      //         font-weight: bold;
      //       }
      //     }
      //     .document {
      //       display: flex;
      //       gap: 5px;
      //       align-items: center;
      //       input {
      //         max-width: 15px !important;
      //       }
      //     }
      //   }
      //   .documentUploadSection {
      //     display: flex;
      //     justify-content: space-between;
      //     align-items: center;
      //     gap: 20px;
      //     h5 {
      //       font-size: var(--primayFont);
      //       // font-weight: 550;
      //     }
      //   }
      //   .buttonPanel {
      //     width: 100%;
      //     display: flex;
      //     gap: 15px;
      //     justify-content: flex-end;
      //     align-items: center;
      //     a {
      //       color: var(--primaryColor);
      //       text-decoration: underline;
      //       font-weight: 600;
      //     }
      //   }
      // }
    }
    .assignmentDetailInput {
      display: flex;
      gap: 10px;
      justify-content: flex-end;
      // margin-top: 20px;
      button {
        span {
          max-height: fit-content;
        }
      }
    }
  }

  .logContainer {
    position: absolute;
    right: 0px;
    top: 15px;
    background-color: var(--whiteColor);
    border: 2px solid #e9e9e9;
    width: 90%;
    max-width: 300px;

    .logCard {
      border-bottom: 2px solid #e9e9e9;
      padding: 8px;
      padding: 15px 10px;
      &:last-child {
        border: none;
      }
      .logProfileCard {
        display: flex;
        // gap: 10px;
        flex-wrap: wrap;
        font-size: var(--secondaryFont);
        justify-content: space-between;

        img {
          width: 25px;
          height: 25px;
          border: 1px solid #e2e2e2;
          border-radius: 50%;
        }
        p {
          flex-basis: calc(100% - 35px);
        }
        span {
          flex-basis: 100%;
          color: var(--textColor);
        }
      }
      .logProfileText {
        p {
          font-size: var(--primaryFont);
        }
      }
    }
  }
}
