.dropdown {
  display: flex;
  flex-direction: column;
  gap: 6px;
  width: 100%;
  position: relative;
  min-width: 120px;
  // text-transform: lowercase;
  cursor: pointer;

  .dropdownPlaceholder {
    display: flex;
    //align-items: center;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    padding: 8px 10px;
    // max-height: 30px;
    .placeHolderTxt {
      color: var(--textColor);
    }
    li {

      &::first-letter {
        text-transform: uppercase;
      }
      &:last-child {
        margin-left: auto !important;
      }
      &:first-child {
        margin-right: auto !important;
        text-align: left;
      }
      svg {
        cursor: pointer;
        margin-left: auto;
      }

      .listContainer {
        li {
          margin: 0 !important;
        }
      }
    }
  }
  ul {
    border: 1px solid var(--blackColor);
    padding: 13px 16px;
    list-style-type: none;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    text-align: right;

    li {
      ul {
        position: absolute;
        padding: 0;
        width: 100%;
        top: 105%;
        background-color: var(--whiteColor);
        box-shadow: 8px 8px 20px rgba(111, 106, 248, 0.15);
        color: var(--secondaryColor);
        z-index: 5;
        position: absolute;
        left: 0;
        right: 0;
        border: 1px solid var(--textColor);
        text-transform: capitalize;
        li {
          text-align: left;
          padding: 10px 16px;
          border-radius: 5px 5px 0px 0px;
          border-bottom: 1px solid var(--textColor);
          &:first-child {
            background-color: var(--backgroundColor);
            display: flex;
            justify-content: space-between;
          }
          &:last-child {
            border-radius: 0px 0px 10px 10px;
            border: none;
          }
          &:hover {
            background-color: var(--secondaryColor);
            color: white;
            border: none;
            border-radius: 0px;
            cursor: pointer;
          }
        }
      }
    }
  }
}
.listContainer {
  overflow: auto;
  max-height: 135px;
}
.dropdowndataOverflowBug{
  white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    // font-size: 12px;
}