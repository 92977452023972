.breadcrumb {
  display: flex;
  flex-direction: column;
  // gap: 6px;
  // padding: 15px 20px;
  gap: 2px;
  //padding: 5px 20px;
  //border: 1px solid rgba(0, 0, 0, 0.1);
  // background-color: var(--whiteColor);
  width: 100%;
  justify-content: center;
  // align-items: center;
  // flex: 1 1 50%;

  .headingBreadCrumb {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    .adminAssessmentContentTop {
      h2 {
        color: var(--primaryColor);
        font-size: large;
        font-weight: 700;
      }
    }
    .headerButtons {
      display: flex;
      gap: 10px;

      button {
        white-space: nowrap;
        svg {
          width: fit-content;
        }
      }
    }

    h3 {
      font-size: 20px;
      position: relative;
      display: flex;
      width: fit-content;
      align-items: flex-start;
      gap: 2px;
      span {
        max-width: 15px;
        margin-top: -2px;
      }
    }
  }

  p {
    color: var(--textColor);
    display: flex;
    justify-content: center;
    gap: 4px;
    font-size: 14px;
    flex-wrap: wrap;
    span {
      white-space: nowrap;
    }
  }
}
@media screen and (max-width: 991px) {
  .breadcrumb {
    // padding-top: 20px;
    flex-direction: row;
    gap: 10px;

    .headingBreadCrumb {
      flex: 1 1 80%;
    }
  }
}

@media screen and (max-width: 767px) {
  .breadcrumb {
    .headingBreadCrumb {
      .headerButtons {
        flex-wrap: wrap;
        justify-content: flex-end;
      }
    }
  }
}
@media  screen and (max-width:550px){
  .breadcrumb{
    .headingBreadCrumb{
      .adminAssessmentContentTop{
        h2{
          // font-size: medium;
        }
      }
    }
  }
}