.userTasksContainer {
  display: flex;
  flex-direction: column;
  gap: 5px;
  // padding: 0px 5px 5px 5px;
  height: 100%;
  width: 100%;

  .tasksBody {
    display: flex;
    flex-direction: column;
    gap: 5px;
    // padding: 9px 8px 9px 9px;

    .tasksIcons {
      display: flex;
      gap: 10px;
      justify-content: right;
      padding-right: 30px;
      svg {
        max-width: 25px;
        cursor: pointer;
      }
    }
  }

  .authData {
    // display: flex;
    // flex-direction: column;
    // align-items: flex-end;
    // flex: 1 1 50%;
    display: flex;
    justify-content: space-between;
    width: 100%;
    background-color: var(--mainBgColor);
    padding: 9px 8px 9px 9px;
    .loggedInUser {
      display: flex;
      flex-direction: column;
      justify-content: right;
      flex: 1 1 70%;

      .loggedInTime {
        text-align: "center";
        font-size: "14px";
      }
      .loggedInTimeLabel {
        font-weight: normal;
      }

      .loggedInUserContainer {
        display: flex;
        gap: 10px;
        border-radius: 10px;
        // align-items: center;
        width: 100%;
        // max-width: 180px;
        // max-width: 250px;
        position: relative;
        // padding: 10px 10px 20px;
        justify-content: flex-end;
        cursor: pointer;
        &:hover {
          .logoutUi {
            display: flex;
          }
        }
        .logoutUi {
          position: absolute;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          top: 95%;
          width: 100%;
          max-width: 180px;
          margin: 0 auto;
          transition: all 0.5s ease;
          display: none;
          background-color: var(--whiteColor);
          padding: 5px 5px;
          border-radius: 5px;
          -webkit-box-shadow: 0px 3px 4px 0px rgba(202, 202, 202, 1);
          -moz-box-shadow: 0px 3px 4px 0px rgba(202, 202, 202, 1);
          box-shadow: 0px 3px 4px 0px rgba(202, 202, 202, 1);
          a {
            color: var(--primaryColor);
            text-decoration: none;
          }
        }

        .userInitial {
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          width: 100%;
          max-width: 40px;
          height: 40px;
          // flex: 1 1 50%;
          background-color: var(--secondaryColor);

          p {
            font-size: var(--primaryFont);
            color: var(--whiteColor);
            margin: 0;
            text-transform: uppercase;
            font-weight: bolder;
            padding: 5px;
          }
        }
        .userInfo {
          // flex: 1 1 50%;
          display: flex;
          flex-direction: column;

          p {
            margin: 0;
            &:nth-of-type(1) {
              font-weight: 600;
              font-size: 14px;

              color: var(--primaryColor);
            }
            &:nth-of-type(2) {
              font-size: 13px;
              color: var(--textColor);
            }
          }
        }
      }
    }
  }
  .tabsHeader {
    display: flex;
    gap: 15px;
    margin-bottom: 10px;
    // padding: 9px 8px 9px 9px;
    .active button {
      border-bottom: 4px solid var(--secondaryColor) !important;
    }
  }
}
.assessmentFilter{
//  flex: 1 1 40%;
 width: 55%;
//  margin-bottom: 1.5rem;
}
.assessmentInfo{
  margin-top: 10px;
  font-weight: 700;
  font-size: var(--primaryFont);
  color: var(--primaryColor);
}
.assessmentInfoSection{
  margin-top: 10px;
  margin-bottom: 1rem;
}
.tabsDropdown{
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
