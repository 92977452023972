.stepCommonInner {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  padding: 35px 0;

  max-width: 92%;
  gap: 30px;
  margin: 0 auto;
  align-items: center;

  h2 {
    &:after {
      content: "";
      right: 10px;
      position: absolute;
    }
  }
  h3 {
    font-size: 1rem;
    padding-bottom: 5px;
    font-weight: 600;
  }
  h4 {
    font-size: 1rem;
    padding-bottom: 5px;
    font-weight: 600;
    position: relative;
  }

  .stepFourHeading {
    display: flex;
    gap: 10px;
    justify-content: center;
    flex-direction: column;
    h3 {
      font-size: 1.5rem;
      font-weight: 600;
    }
    p {
      font-size: var(--secondaryFont);
      color: var(--textColor);
      padding-bottom: 20px;
    }
  }
  .stepCommonForm {
    display: flex;
    text-align: left;
    flex-direction: column;
    gap: 30px;
    width: 100%;
    justify-content: center;
    gap: 10px;

    .stepFourUl {
      font-weight: 600;
      list-style-type: none;
      list-style: none;
      padding-left: 0;
      color: var(--secondaryColor);
      width: 100%;
      ul {
        padding-left: 0;
        color: var(--blackColor);
        p {
          color: var(--redColor);
          font-style: italic;
        }
        ul {
          padding-left: 0;
          font-weight: 600;
          li {
            padding-left: 0px;
          }
        }
        li {
          font-weight: normal;

          ul {
            padding-bottom: 10px;
            gap: 20px;
            li {
              color: #000;
            }
          }
        }
      }

      li {
        list-style-type: none;
      }
    }
    input {
      width: 100%;
      border-radius: 4px;
      padding: 10px;
      border: 1px solid var(--textColor);
    }
  }
}

.pageBtn {
  justify-content: right;
  display: flex;
  align-items: center;
  width: 100%;
  gap: 10px;

  .nextPageBtnBar {
    width: 100%;
    max-width: 220px;
    display: flex;
    justify-content: space-between;
    gap: 10px;
    align-items: center;
    button {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 5px;
    }
  }
}

.selectAssessContainer {
  display: flex;
  text-align: left;
  gap: 20px;
  width: 100%;

  .regionCountAssess {
    display: flex;
    gap: 15px;
    flex-wrap: wrap;
    width: 100%;
    h2 {
      color: var(--primaryColor);
      background-color: transparent;
      font-weight: 700;
      padding: 0;
      padding-bottom: 5px !important;
      font-size: 1.1rem;
      text-align: left;

      // display: flex;
      // justify-content: right;
      // align-items: center;
      // transition: 0.3s all ease;
      //cursor: pointer;

      svg {
        margin-left: auto;
      }
    }

    .regionCountDiv {
      flex-basis: 25%;

      ul {
        list-style-type: none;
        padding-left: 0;

        li {
          // margin-top: 10px !important;
          // border: 1px solid var(--tableBorderColor);

          h3 {
            padding: 10px 15px;
            // border-bottom: 1px solid var(--tableBorderColor);
            transition: 0.3s all ease;
          }
        }
        li:first-child {
          margin-top: 0px !important;
        }
      }
    }
    .assessmentDiv {
      display: flex;
      //gap: 10px;
      flex-direction: column;
      flex-wrap: wrap;
      //flex-basis: 45%;
      flex: 1;
      .assessmentHeadings {
        border: 1px solid #c8c8c8;
        padding: 10px 0;
        h2 {
          text-align: center;
        }
        h4 {
          color: var(--textColor);
          text-align: center;
        }
      }
      .selectCount {
        display: flex;
        gap: 10px;
        justify-content: space-between;
        font-weight: 600;
        //   background-color: var(--tableBorderColor);
        padding: 5px 10px;
        align-items: center;
        border-left: 1px solid #c8c8c8;
        border-right: 1px solid #c8c8c8;
        border-bottom: 1px solid #c8c8c8;

        & > div {
          margin: 0;
        }
        span {
          color: var(--secondaryColor);
        }
        .selectAllCheck {
          font-size: 1rem;
          margin: 10px 0;
          //   padding: 0 10px;

          & > div {
            margin-bottom: 0;
            display: inline-flex !important;
            width: auto !important;
          }
        }
      }

      .assessmentCheck {
        display: flex;
        // flex-direction: column;
        //align-items: center;
        // padding-left: 25px;
        // gap: 6px;
        flex-wrap: wrap;
        justify-content: space-between;
        // width: fit-content;
        // justify-content: center;
        // align-self: center;

        .singleCheck {
          //  border-bottom: 2px solid var(--tableBorderColor);
          // border-radius: 5px;
          padding: 10px;
          width: 100%;
          //   flex-basis: 48%;
          //padding-left: 35px;
          & > div {
            margin-bottom: 0;
          }
        }
        .singleCheck.checkClass {
          background-color: var(--tableBorderColor);
        }
        label {
          padding-left: 8px;
        }
        h5 {
          font-size: 16px;
          font-weight: 700;
          width: 100%;
          color: var(--secondaryColor);
          display: flex;
          align-items: center;
        }
        h1 {
          font-size: 13px;
          font-weight: 800;
          width: 100%;
        }
        .noCheckDiv {
          border: 1px solid #c8c8c8;
          padding: 10px;
          width: 100%;
        }
      }
    }
  }

  // .selectedAssessments {
  //   padding: 10px;
  //    flex-basis: 24%;
  //   display: flex;
  //   gap: 10px;
  //  // flex: 0;
  //   flex-direction: column;
  //   border-left: 1px solid var(--tableBorderColor);
  //   // padding-left: 20px;

  //   h2 {
  //     font-size: 1rem;
  //     font-weight: 700;
  //     background-color: transparent;
  //     padding: 0;
  //     color: var(--primaryColor);
  //     padding-bottom: 5px;
  //     font-size: 1rem;
  //   }
  //   h3 {
  //     padding-bottom: 0;
  //     font-weight: 400;
  //   }
  //   .selectedAssessCard {
  //     display: flex;
  //     flex-direction: column;
  //     // border: 1px solid var(--tableBorderColor);
  //     // border-radius: 5px;
  //     // padding: 5px 10px;
  //     // gap: 10px;
  //     padding-left: 30px;
  //     position: relative;

  //     .tag {
  //       position: absolute;
  //       width: 20px;
  //       height: 20px;
  //       border-radius: 50%;
  //       background-color: var(--tableBorderColor);
  //       left: 0;
  //       top: 5px;
  //       cursor: pointer;

  //       svg {
  //         max-width: 10px;
  //         position: absolute;
  //         left: 0;
  //         right: 0;
  //         top: 0;
  //         bottom: 0;
  //         margin: auto;
  //         cursor: pointer;
  //         &:hover {
  //           fill: var(--redColor);
  //         }
  //       }

  //       // &:before {
  //       //   transform: rotate(45deg);
  //       //   height: 12px;
  //       //   width: 7px;
  //       //   border-bottom: 3px solid var(--secondaryColor);
  //       //   border-right: 3px solid var(--secondaryColor);
  //       //   position: absolute;
  //       //   left: 6px;
  //       //   content: "";
  //       //   top: 3px;
  //       // }
  //     }

  //     .selectedAssessInner {
  //       display: flex;
  //       width: 100%;
  //       justify-content: space-between;
  //       align-self: flex-end;
  //       gap: 10px;
  //       color: var(--textColor);
  //       font-size: calc(var(--secondaryFont) - 2px);
  //     }
  //   }
  // }
}

h2 button {
  padding: 0 !important;
  font-size: var(--primaryFont) !important;
  font-weight: 600;

  &:not(.collapsed) {
    color: var(--blackColor) !important;
    background-color: transparent !important;
    box-shadow: none !important;
  }
  &:after {
    width: 14px !important;
    height: 14px !important;
    background-size: contain !important;
  }
}

.accordionContainer {
  background-color: var(--tableBorderColor) !important;
  padding: 15px 15px 5px !important;
  height: 100%;
  & > button {
    margin: 5px 0 15px;
  }
  .accItem {
    margin-bottom: 12px;
    margin-top: 8px;
    border: none;
    background-color: transparent !important;
    border-bottom: 1px solid var(--textColor);

    .accBody {
      padding: 6px 0px !important;
    }

    &:last-child {
      border: none;
    }
    h4 {
      font-weight: normal;
      font-size: var(--primaryFont);
    }
  }
}

@media only screen and (max-width: 991px) {
  .stepCommonInner {
    max-width: 100%;
    padding: 35px 20px;
  }
}
