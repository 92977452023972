.userTasksContainer {
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 0px 5px 5px 5px;
  height: 100%;
  width: 100%;

  .tasksBody {
    display: flex;
    flex-direction: column;
    gap: 5px;

    .tasksIcons {
      display: flex;
      gap: 10px;
      justify-content: right;
      padding-right: 30px;
      svg {
        max-width: 25px;
        cursor: pointer;
      }
    }
  }
  .tabsHeader {
    display: flex;
    gap: 15px;
    margin-bottom: 10px;
    .active button {
      border-bottom: 4px solid var(--secondaryColor) !important;
    }
  }
  .selectAssessOrgUser {
    max-width: 300px;
    width: 80%;
    margin-top: 10px;
  }
}
