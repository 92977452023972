.dashboard {
  width: 100%;
}
.breadcrumb {
  display: flex;
  align-items: flex-start;
  .breadcrumbcontainer {
    display: flex;
    margin-bottom: 10px;
    // margin-left: 10px;
    flex-wrap: wrap;

    p {
      flex-basis: 100%;
      order: 2;
      // padding-left: 38px;
    }
  }
}

.dashExecutiveContainer {
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 0px 5px 5px 5px;
  height: 90%;
  width: 100%;

  .dashExecutiveBody {
    display: flex;
    flex-direction: column;
    // gap: 20px;
    padding: 20px 40px;
    // height: 100%;
    border: 1px solid rgba(0, 0, 0, 0.1);
    background-color: var(--whiteColor);
    flex: 1 1;

    h4 {
      font-weight: 600;
      text-transform: uppercase;
      font-size: var(--primaryFont);
      // color: var(--textColor);
      padding-bottom: 10px;
    }

    p {
      color: var(--primaryColor);
      font-size: 22px;
      font-weight: 700;
    }

    .dashExecutiveGraph {
      display: flex;
      // gap:20px;
      align-items: center;
      width: 100%;
      margin: 0px;
      // overflow: hidden;

      canvas {
        // max-height: 300px !important;
        width: auto !important;
        max-width: 100% !important;
      }

      .dashExecGraphCircle {
        flex: 1 1 35%;
        padding-left: 10px;

        p {
          transform: translate(20%, -1000%);
        }
      }

      .dashExecGraphBar {
        flex: 1 1 50%;
        // margin-right: 10px;

        p {
          transform: translate(30%, -1000%);
        }
      }
    }

    .dashExecGraphBarFull {
      width: 100%;
      padding-left: 10px;

      .dashExecArea {
        display: flex;
        gap: 20px;
        align-items: stretch;
        flex-wrap: wrap;

        // justify-content: space-between;
        .card {
          background-color: var(--whiteColor);
          border: 2px solid var(--tableBorderColor);
          border-radius: 5px;
          padding: 20px 15px;
          display: flex;
          justify-content: space-between;
          flex-direction: column;
          min-width: 15%;
          cursor: pointer;
          max-width: 250px;

          h3 {
            font-weight: 600;
            text-transform: uppercase;
            font-size: 14px;
            color: var(--textColor);
            padding-bottom: 10px;
          }

          .progressBarInCard {
            margin-top: auto;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1250px) {
  .dashExecutiveContainer {
    .dashExecutiveBody {
      .dashExecutiveGraph {
        flex-direction: column;
      }
    }
  }
}

@media screen and (max-width: 990px) {
  .dashExecutiveContainer {
    .dashExecutiveBody {
      .dashExecutiveGraph {
        flex-direction: column;

        .dashExecGraphBar {
          width: 100%;

          p {
            transform: translate(20%, -597%);
          }
        }
      }
    }
  }
}
