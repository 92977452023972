.userManagementContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
  // padding: 0px 5px 5px 5px;
  height: 100%;
  width: 100%;

  .userManagementHeader {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 5px 20px;
    p {
      color: var(--textColor);
      display: flex;
      gap: 20px;
      margin: 0;
    }
  }

  .authData {
    // display: flex;
    // flex-direction: column;
    // align-items: flex-end;
    // flex: 1 1 50%;
    display: flex;
    justify-content: space-between;
    width: 100%;
    background-color: var(--mainBgColor);
    padding: 9px 8px 9px 9px;
    .loggedInUser {
      display: flex;
      flex-direction: column;
      justify-content: right;
      flex: 1 1 70%;

      .loggedInTime {
        text-align: "center";
        font-size: "14px";
      }
      .loggedInTimeLabel {
        font-weight: normal;
      }

      .loggedInUserContainer {
        display: flex;
        gap: 10px;
        border-radius: 10px;
        // align-items: center;
        width: 100%;
        // max-width: 180px;
        // max-width: 250px;
        position: relative;
        // padding: 10px 10px 20px;
        justify-content: flex-end;
        cursor: pointer;
        &:hover {
          .logoutUi {
            display: flex;
          }
        }
        .logoutUi {
          position: absolute;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          top: 95%;
          width: 100%;
          max-width: 180px;
          margin: 0 auto;
          transition: all 0.5s ease;
          display: none;
          background-color: var(--whiteColor);
          padding: 5px 5px;
          border-radius: 5px;
          -webkit-box-shadow: 0px 3px 4px 0px rgba(202, 202, 202, 1);
          -moz-box-shadow: 0px 3px 4px 0px rgba(202, 202, 202, 1);
          box-shadow: 0px 3px 4px 0px rgba(202, 202, 202, 1);
          a {
            color: var(--primaryColor);
            text-decoration: none;
          }
        }

        .userInitial {
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          width: 100%;
          max-width: 40px;
          height: 40px;
          // flex: 1 1 50%;
          background-color: var(--secondaryColor);

          p {
            font-size: var(--primaryFont);
            color: var(--whiteColor);
            margin: 0;
            text-transform: uppercase;
            font-weight: bolder;
            padding: 5px;
          }
        }
        .userInfo {
          // flex: 1 1 50%;
          display: flex;
          flex-direction: column;

          p {
            margin: 0;
            &:nth-of-type(1) {
              font-weight: 600;
              font-size: 14px;
              
              color: var(--primaryColor);
            }
            &:nth-of-type(2) {
              font-size: 13px;
              color: var(--textColor);
            }
          }
        }
      }
    }
  }
  .userManagementBody {
    display: flex;
    flex-direction: column;
    gap: 20px;
    // padding: 9px 8px 9px 9px;
    height: 100%;
    // box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    // border: 1px solid rgba(0, 0, 0, 0.1);
    background-color: var(--whiteColor);
    flex: 1;

    .userDataRowOne {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-radius: 5px;
      flex-wrap: wrap;
      gap: 5px;
    }
    .userDataRowTable {
      max-width: 100%;
      // height: 100%;
      // max-height: 424px;
      //max-width: 1250px;
      overflow-x: auto;
      height: 100%;

      td {
        td {
          display: flex;
          flex-direction: column;
          gap: 8px;
          padding: 0 !important;
        }
        .timerSpan {
          display: flex;
          justify-content: center;
          max-width: 100px;
        }
      }
    }
  }
  .orgLoader {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    border: 1px solid rgba(0, 0, 0, 0.1);
    background-color: var(--whiteColor);
  }
}
.dropdownUl {
  max-width: 140px;
  // margin: 0 auto;
}
.anchorButton {
  background: none !important;
  border: none;
  padding: 0 !important;
  /*optional*/
  /*input has OS specific font-family*/
  color: #069;
  text-decoration: underline;
  cursor: pointer;
}

.invitePending {
  margin-left: 20px;
}

@media screen and (max-width: 990px) {
  // .userManagementContainer {
  //   .userManagementRow {
  //     .userManagementColTwo {
  //       .userManagementData {
  //         .userDataRowTable {
  //           max-width: 850px;
  //           max-height: 500px;
  //         }
  //       }
  //     }
  //   }
  // }
}
@media screen and (max-width: 875px) {
  // .userManagementContainer {
  //   .userManagementRow {
  //     .userManagementColTwo {
  //       .userManagementData {
  //         width: 100%;
  //         max-width: 540px;
  //         max-height: 850px;
  //         .userDataRowTable {
  //           width: 100%;
  //         }
  //       }
  //     }
  //   }
  // }
}
@media screen and (max-width: 550px) {
  .userManagementContainer {
    .userManagementRow {
      .userManagementCol1 {
        display: none;
      }
      // .userManagementColTwo {
      //   padding: 20px;
      //   .userManagementData {
      //     .userDataRowOne {
      //       justify-content: center;
      //       gap: 50px;
      //       button {
      //         font-size: var(--secondaryFont) !important;
      //         font-weight: normal !important;
      //         padding: 0px !important;
      //       }
      //     }
      //     .userDataRowTable {
      //       width: 100%;
      //       max-width: 340px;
      //       max-height: 550px;
      //     }
      //   }
      // }
    }
  }
}
