.MainContainer{
    width: 100%;
    height: 100%;

}
.DataContainer{
    margin: 30px 0px;
    padding: 20px 10px;
    border: 1px solid #dfe2eb;
    width: 100%;
    // height: 80%;

    .pageHead{

        text-align: center;
        padding: 0px ;
        font-size: 20px;
        color: var(--primaryColor);
        font-weight: bolder;
    }

}


.DetailsContainer {
    display: grid;
    grid-template-columns: repeat(3, 0.5fr); 
    gap: 25px;
   
   

    p{
        margin: 20px 10px;
        padding: 10px 20px;
        

        span{
           
            font-size: 18px;
            font-weight: bold;
            padding-right: 10px;

        }
    }

}

.ParaContainer{
    color: #0097ce;
    padding: 20px 10px;
    font-weight: bolder;

}
.breadCrumbManager{
    display: flex;
    gap: 10px;
}
@media screen and (max-width:991px){
    .breadCrumbManager{
        align-items: flex-start;
    }
}
@media screen and (max-width:750px){
    .DetailsContainer{
        grid-template-columns: repeat(2, 0.5fr)
    }
}
@media screen and (max-width:550px){
    .DetailsContainer{
        grid-template-columns: repeat(1, 0.5fr)
    }
}