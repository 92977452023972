.formikError {
  color: var(--redColor) !important;
  font-size: 12px !important;
  font-weight: normal !important;
  // text-align: right;
  // position: absolute;
  padding-top: 2px;
  text-align: left;
  top: 96%;
  left: 0;
}
