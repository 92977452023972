.successQuestionaireContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: auto;
  gap: 20px;
  padding: 70px;
  .sucessContainer {
    h3 {
      color: var(--primaryColor);
      font-weight: 700;
      font-size: 30px;
    }
    .successCheck {
      max-width: 100%;
      max-height: 100%;
    }
  }
}

.topBtnContainer {
  display: flex;
  gap: 24px;
}
.headingBlock {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  // margin-bottom: 50px;

  .messageBlock{
    font-weight: bold;
  }
  .chapterBlock{
    font-weight: bold;
    color: grey;
  }

  p {
    font-weight: 600;
    font-size: var(--primaryFont);
  }
  .ropaButtonContainer {
    display: flex;
    gap: 15px;
  }
  .backButtonContainer {
    display: flex;
    gap: 15px;
    color: black;
    background: none;
  }
}
