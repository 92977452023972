.userPopup {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 400px;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  overflow: hidden;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  transition: all 0.5s ease;
  &:before {
    content: "";
    position: fixed;
    width: 100%;
    height: 100%;
    inset: 0;
    background-color: rgba(0, 0, 0, 0.5);
    background-position: center;
    background-repeat: no-repeat;
    overflow: hidden;
  }
  .userPopupInner {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    gap: 35px;
    background-color: var(--whiteColor);
    z-index: 2;
    overflow: hidden;
    padding: 15px 30px;
    .userPopupHeader {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      img {
        max-width: 30px !important;
        max-height: 42px;
      }
    }
    .form {
      display: flex;
      flex-direction: column;
      gap: 15px;
      .dropdownPanel {
        display: flex;
        flex-direction: column;
      }
      input {
        width: 100%;
        border-radius: 5px;
        padding: 5px 10px;
      }
    }
    .buttonPanel {
      width: 100%;
      display: flex;
      gap: 15px;
      justify-content: flex-end;
      align-items: center;
    }
  }
}
