.statusBar {
  width: 100%;
  height: 10px;
  background-color: var(--tableBorderColor);
  border-radius: 5px;
  overflow: hidden;
  max-width: 200px;

  .statusBarProgress {
    height: 100%;
    background-color: var(--secondaryColor);
    transition: width 0.3s;
  }
}
.fullStatus {
  .statusBarProgress {
    background-color: var(--greenColor);
  }
}
