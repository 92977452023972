.button {
  position: relative;

  // display: inline-block;
  // background-color: #ccc;
  // border: none;
  // color: #fff;
  // font-size: 16px;
  // cursor: not-allowed;

  // span {
  //   margin: 0;
  //   max-width: 15px;
  //   max-height: 20px;
  // }
  .disabledIcon{
    svg{
      fill: var(--secondaryColor) !important;
    }
  }
}

.loader {
  transform: translate(-50%, -50%);
  animation: spin 1s infinite linear;
}

@keyframes spin {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
