.stepCommonInner {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  padding: 35px;
  max-width: 680px;
  // border: 1px solid var(--textColor);
  //border-radius: 5px;
  gap: 30px;
  flex: 1;

  .stepTwoHeading {
    display: flex;
    gap: 10px;
    justify-content: center;
    flex-direction: column;
    h3 {
      font-size: 1.5rem;
      font-weight: 600;
    }
    p {
      font-size: var(--secondaryFont);
      color: var(--textColor);
      padding-bottom: 20px;
    }
  }

  .stepTwoForm {
    display: flex;
    flex-direction: column;
    gap: 30px;
    width: 100%;
    text-align: left;
    .dropdownData {
      p {
        font-weight: 600;
        padding-bottom: 20px;
      }
      .editOrgReactSelect>div > div{
        cursor: pointer;
      }
    }
    input {
      width: 100%;
      border-radius: 4px;
      padding: 10px;
      border: 1px solid var(--textColor);
    }
    .pageBtn {
      justify-content: flex-end;
      display: flex;
      align-items: center;
      width: 100%;
      gap: 10px;
    }
  }
}
