.editOrganisationContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 0px 10px 10px 10px;
  height: 100%;
  width: 100%;
  .editOrganisationHead {
    padding: 20px 20px;
    background-color: #ffffff;
    .textOnlyEditOrg {
      display: flex;
      gap: 10px;
      flex-direction: column;
    }
    p {
      color: var(--textColor);
      display: flex;
      gap: 4px;
      font-size: var(--secondaryFont);
    }
  }
  form {
    display: flex;
    flex-direction: column;
  }
  .loginForm {
    // background-color: #f5f5f5;
    text-align: center;
    width: 100%;
    max-width: 600px;
    min-width: 400px;
    background-color: #ffffff;
    padding: 20px 0;
  }
}
.login {
  display: flex;
  flex-direction: row;
  min-height: 100%;
  .loginSection1 {
    flex: 1 1 50%;
    // background-color: var(--secondaryColor);
    width: 100%;

    .loginForm {
      display: flex;
      align-items: flex-end;
      position: relative;
      justify-content: center;
      margin: 0 auto;
      width: 100%;
      flex-direction: column;
      max-width: 100%;
      .backBtn button {
        position: absolute;
        top: 25px;
        left: 10px;
        height: auto;
      }

      & > button {
        margin-right: 10px;
      }
      .loginFormInner {
        display: flex;
        flex-direction: column;
        gap: 20px;
        width: 100%;
        padding-left: 10px;
        padding-right: 10px;
        max-width: 950px;
        margin: 0 auto;
        position: relative;
        padding-top: 15px;

        & > a {
          text-align: left;
        }
        & > button {
          align-self: flex-end;
        }
        h2 {
          padding-bottom: 30px;
          font-weight: 600;
          color: var(--primaryColor);
          font-size: 22px;
        }
        h3 {
          text-align: left;
          padding: 10px 0;
          font-size: calc(var(--primaryFont) + 2px);
          font-weight: 600;

          margin-bottom: 20px;
          text-transform: uppercase;
          color: var(--secondaryColor);
        }
        h4 {
          text-align: left;
          padding: 20px 0 0 0;
          // font-size: calc(var(--primaryFont) - 2px);
          // color: var(--textColor);
          font-weight: 600;

          text-transform: uppercase;

          font-size: calc(var(--primaryFont) + 2px);
          color: var(--secondaryColor);
        }
        .tableEditOrg {
          text-align: left;
          overflow: auto;
          table {
            tr {
              td {
                text-align: left;
              }
            }
          }
        }
        .assessmentBtnHead {
          display: flex;
          justify-content: space-between;
          align-items: center;
          gap: 10px;

          h4 {
            padding: 0;
          }
        }
        .flexEditOrg {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          align-items: flex-start;
          gap: 20px 10px;

          .editOrgInput {
            display: flex;
            width: 100%;
            justify-content: flex-start;
            align-items: center;
            gap: 10px;
            text-align: left;
            flex-basis: 49%;

            label {
              font-weight: 600;
              text-align: left;
              line-height: initial;
            }
            .editOrgSelect {
              padding: 8.2px 10px;
            }

            .formGroup > div {
              flex-wrap: wrap;
            }
          }
          .editOrgInput > div {
            display: flex;
            width: 100%;
            gap: 6px;
            flex-direction: column;
            padding-bottom: 0;
          }
        }
        .flexEditLocation {
          display: flex;
          gap: 10px;
          flex-direction: column;
          .editLoaction {
            .regionBranch {
              text-align: left;
              padding: 20px 0 10px 0;
              font-size: calc(var(--primaryFont) - 2px);
              font-weight: 600;
              color: var(--textColor);
              text-transform: uppercase;
              flex-basis: 100%;
            }
            .editOrgInput {
              flex-basis: 30%;
            }
          }
        }

        input {
          font-size: var(--secondaryFont);
          padding: 10px 7px;
          border-radius: 5px;
          width: 100%;
          max-height: 42px;
          border: 1px solid var(--blackColor);
          &:focus {
            border-color: var(--textColor);
          }
        }
        input[type="radio"] {
          width: auto;
          padding: 0;
        }
        // label {
        // }
        .formInnerBtnSection {
          display: flex;
          // justify-content: space-between;
          justify-content: flex-end;
        }
      }
    }
  }

  .loginSection2 {
    flex: 1 1 50%;
    display: flex;
    background-color: var(--secondaryColor);
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 50px;
    .sampleImage {
      width: 100%;
    }
  }
}

@media screen and (max-width: 991px) {
  // .login {
  //   .loginSection1 {
  //     .loginForm {
  //       height: 100%;
  //       padding: 0 20px;
  //     }
  //   }
  // }
  // .login {
  //   .loginSection1 {
  //     .loginForm {
  //       height: 100%;
  //       padding: 0 20px;
  //     }
  //   }
  // }

  .login .loginSection1 {
    .loginForm {
      .loginFormInner {
        h2 {
          padding-bottom: 0px;
        }
        .flexEditOrg {
          gap: 20px;
          .editOrgInput {
            flex-grow: 1;
            flex-wrap: wrap;
          }
        }
        .orgRegionsParent {
          .orgRegions {
            gap: 20px;
            position: relative;

            .editField {
              flex-grow: 1;
              flex-basis: 100%;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 550px) {
  .login .loginSection1 {
    .loginForm {
      .loginFormInner {
        .flexEditOrg {
          .editOrgInput {
            //  flex-direction: column;
          }
        }
      }
    }
  }
}
