.paginationWrapper {
  padding: 4px 10px;
  display: flex;
  justify-content: center;
  gap: 5px;
  background-color: var(--mainBgColor);
  max-width: fit-content;
  margin: 0 auto;
  border-radius: 4px;
  margin-top: 30px;

  button {
    background-color: transparent;
    border: none;
    color: var(--secondaryColor);
    padding: 5px 15px;
    border-radius: 4px;
    // &:hover {
    //   background-color: var(--secondaryColor);
    //   color: var(--whiteColor);
    // }
  }
  .active {
    background-color: var(--secondaryColor);
    color: var(--whiteColor);
  }
}

.separator {
  width: 1rem;
  margin: 0 0.25rem;
}

.pageItem {
  background: transparent;
  border: none;
  height: 2rem;
  width: 2rem;
  margin: 0 0.25rem;
  border-radius: 50%;
  font-weight: 600;

  &:hover {
    text-decoration: underline;
  }

  &:focus {
    outline: 0;
  }
}

.sides {
  box-shadow: transparent 0px 0px 0px 1px, transparent 0px 0px 0px 4px,
    rgba(0, 0, 0, 0.18) 0px 2px 4px;

  &:hover {
    text-decoration: none;
    box-shadow: transparent 0px 0px 0px 1px, transparent 0px 0px 0px 4px,
      rgba(0, 0, 0, 0.12) 0px 6px 16px;
  }
}
