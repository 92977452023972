.selectUi {
  display: flex;
  flex-direction: column;
  // gap: 6px;
  width: 100%;
  position: relative;
  label {
    display: flex;
    align-items: center;
  }

  .dropdownPlaceholder {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    max-height: 42px;
    li {
      svg {
        cursor: pointer;
      }
    }
  }
  ul {
    border: 1px solid var(--textColor);
    padding: 13px 16px;
    list-style-type: none;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    text-align: right;

    li {
      ul {
        position: absolute;
        padding: 0;
        width: 100%;
        top: 45px;
        left: 0;
        border: none;
        background-color: var(--whiteColor);
        box-shadow: 8px 8px 20px rgba(111, 106, 248, 0.15);
        color: var(--secondaryColor);
        z-index: 5;
        position: absolute;
        left: 0;
        right: 0;
        width: 100%;
        border: 1px solid var(--textColor);

        li {
          text-align: left;
          padding: 15px 16px;
          border-radius: 10px 10px 0px 0px;
          border-bottom: 1px solid var(--textColor);
          &:first-child {
            background-color: var(--backgroundColor);
            display: flex;
            justify-content: space-between;
          }
          &:last-child {
            border-radius: 0px 0px 10px 10px;
            border: none;
          }
          &:hover {
            background-color: var(--secondaryColor);
            color: white;
            border: none;
            border-radius: 0px;
          }
        }
      }
    }
  }
}
.selectUi > div > div {
  cursor: pointer;
  border-radius: 4px;
}
.selectUi > div > div > div > div {
  text-align: start;
}
