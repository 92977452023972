.tasksCardParent {
  .tasksCard {
    border: 1px solid rgba(0, 0, 0, 0.1);
    background-color: var(--whiteColor);
    padding: 15px 25px;
    display: flex;
    flex-direction: column;
    gap: 5px;
    border-radius: 5px;

    .cardTop {
      display: flex;
      text-align: center;
      justify-content: space-between;

      color: var(--primaryColor);
      align-items: center;

      span {
        font-weight: 700;
        font-size: var(--primaryFont);

        &:first-child {
          // border: 2px solid var(--secondaryColor);
          // border-radius: 12px;
          // padding: 0px 5px;
          // font-weight: 600;
          // font-size: calc(var(--secondaryFont) - 1px);
        }
      }
      button {
        span {
          padding: 0 !important;
        }
      }
    }
    .cardChapter {
      font-weight: 600;
      color: var(--textColor);
    }
    .cardMid {
      font-size: var(--primaryFont);
      font-weight: 600;
    }
    .cardBottom {
      display: flex;
      gap: 10px;
      justify-content: space-between;
      align-items: center;

      font-size: calc(var(--secondaryFont) - 1px);
      font-weight: 600;
      color: var(--textColor);

      span {
        display: flex;
        gap: 5px;
        align-items: center;
        white-space: pre;
        p {
          font-weight: 700;
          color: var(--blackColor);
        }
        svg {
          max-height: 14px;
          width: auto;
          min-width: 14px;
        }
      }

      .dueWeek {
        color: var(--redColor);
        p {
          color: var(--redColor);
        }
      }
    }
  }
  h3 {
    font-weight: 600;
    text-transform: uppercase;
    color: var(--textColor);
    font-size: var(--secondaryFont);
  }
}

.tasksCardGridParent {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 5px;
  .tasksCard {
    display: flex;
    flex-direction: column;
    flex: 1 1 0;
  }
  .taskOnHover:hover {
    box-shadow: 0 0 4px 0px #e2e2e2;
    cursor: pointer;
  }
}
