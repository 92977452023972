.forgotStatus {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;

  .forgotStatusDiv {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    max-width: 400px;
    margin: auto;
    align-items: center;
    justify-content: center;
    h1 {
      font-size: 1.5rem;
      font-weight: 600;
    }
    p {
      font-size: var(--secondaryFont);
      color: var(--textColor);
      padding-bottom: 20px;
      span {
        display: block;
        text-align: center;
      }
    }
  }
  .backToLogin {
    display: flex;
    gap: 5px;
    align-items: center;
  }
  .formInnerBtnSection {
    display: flex;
    gap: 10px;
    width: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
}
