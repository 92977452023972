.dashbrdVendorContainer {
  display: flex;
  gap: 8px;
  flex-direction: column;
  // padding: 10px 20px 0px 20px;

  .dashbrdVendorCard {
    border: 1px solid rgba(0, 0, 0, 0.1);
    background-color: var(--whiteColor);
    // padding: 20px;
    display: flex;
    gap: 5px;
    flex-direction: column;

    .dashbrdVendorHead {
      text-transform: uppercase;
      font-size: var(--secondaryFont);
      color: var(--textColor);
      font-weight: 800;
    }
    .dashbrdVendorHeadBg {
      background-color: var(--backgroundColor);
      padding: 15px 20px;
    }
    .dashbrdVendorflexSpace {
      padding: 15px 20px;
      display: flex;
      gap: 10px;
      flex-direction: column;
    }
    .dashbrdVendorflex {
      display: flex;
      gap: 10px;
      align-items: center;

      .dashbrdVendorTitle {
        font-size: calc(var(--primaryFont) + 2px);
        min-width: 125px;
        font-weight: 700;
      }

      .dashbrdVendorAssessment {
        font-size: var(--secondaryFont);
        display: flex;
        gap: 10px;
        font-weight: 600;
        span:nth-child(odd) {
          cursor: pointer;
          transition: all 0.5s ease;
          &:hover {
            color: var(--secondaryColor);
          }
        }
      }
    }

    .dashbrdVendorflexUS {
      flex-wrap: wrap;
      flex-direction: column;
      align-items: flex-start;

      .dashbrdVendorTitle {
        flex-basis: 100%;
      }
      .dashbrdVendorHead {
        min-width: 125px;
      }
    }
  }
}
.viewSvg {
  min-width: 20px !important;
}
.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
}
.innerContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
  background-color: var(--mainBgColor);
  padding: 9px 8px 9px 9px;
  // align-items: center;
}

.breadCrumb2 {
  display: flex;
  justify-content: end;
  align-items: end;
}
.authData {
  // display: flex;
  // flex-direction: column;
  // align-items: flex-end;
  flex: 1 1 50%;

  .loggedInUser {
    display: flex;
    flex-direction: column;
    justify-content: right;
    flex: 1 1 70%;

    .loggedInTime {
      text-align: "center";
      font-size: "14px";
    }
    .loggedInTimeLabel {
      font-weight: normal;
    }

    .loggedInUserContainer {
      display: flex;
      gap: 10px;
      border-radius: 10px;
      // align-items: center;
      width: 100%;
      // max-width: 180px;
      // max-width: 250px;
      position: relative;
      // padding: 10px 10px 20px;
      justify-content: flex-end;
      cursor: pointer;
      &:hover {
        .logoutUi {
          display: flex;
        }
      }
      .logoutUi {
        position: absolute;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        top: 95%;
        width: 100%;
        max-width: 180px;
        margin: 0 auto;
        transition: all 0.5s ease;
        display: none;
        background-color: var(--whiteColor);
        padding: 5px 5px;
        border-radius: 5px;
        -webkit-box-shadow: 0px 3px 4px 0px rgba(202, 202, 202, 1);
        -moz-box-shadow: 0px 3px 4px 0px rgba(202, 202, 202, 1);
        box-shadow: 0px 3px 4px 0px rgba(202, 202, 202, 1);
        a {
          color: var(--primaryColor);
          text-decoration: none;
        }
      }

      .userInitial {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        width: 100%;
        max-width: 40px;
        height: 40px;
        // flex: 1 1 50%;
        background-color: var(--secondaryColor);

        p {
          font-size: var(--primaryFont);
          color: var(--whiteColor);
          margin: 0;
          text-transform: uppercase;
          font-weight: bolder;
          padding: 5px;
        }
      }
      .userInfo {
        // flex: 1 1 50%;
        display: flex;
        flex-direction: column;

        p {
          margin: 0;
          &:nth-of-type(1) {
            font-weight: 600;
            font-size: 14px;

            color: var(--primaryColor);
          }
          &:nth-of-type(2) {
            font-size: 13px;
            color: var(--textColor);
          }
        }
      }
    }
  }
}
.paginationControls {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 20px !important;
  padding-bottom: 10px;

  button {
    min-width: 75px;
  }

  span {
    text-decoration: underline;
  }
}
.assessmentheader {
  display: flex;
  align-items: center;
  // gap: 10px;
  justify-content: end;
  // padding: 10px 20px 0px 20px;
  margin-bottom: 10px;
}
.assesmentLoader {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60vh;
}
.iiconSvg {
  width: 10px;
  height: 10px;
  // display: none;
}
@media screen and (max-width: 750px) {
  .dashbrdVendorContainer {
    display: flex;
    gap: 8px;
    flex-direction: column;
    width: 100%;
    overflow: auto;
  }
}
