// .checkbox {
//   input {
//     max-width: 10px;
//     cursor: pointer;
//   }
// }
.checkbox {
  -webkit-appearance: checkbox; /* Chrome, Safari, Opera */
  -moz-appearance: checkbox; /* Firefox */
  -ms-appearance: checkbox; /* not currently supported */
}
