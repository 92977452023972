.stepCommonInner {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  padding: 35px 0;
  max-width: 660px;
  // border: 1px solid var(--textColor);
  //border-radius: 5px;
  gap: 30px;
  flex: 1;

  .stepCommonHeading {
    display: flex;
    gap: 10px;
    justify-content: center;
    flex-direction: column;
    h3 {
      // color: var(--textColor);
      // font-size: var(--primaryFont);
      // font-weight: bold;

      font-size: 1.5rem;
      font-weight: 600;
    }
    p {
      font-size: var(--secondaryFont);
      color: var(--textColor);
      padding-bottom: 20px;
    }
    // h3 {
    //   color: var(--textColor);
    //   font-weight: 600;
    //   flex: 1 1 30%;
    //   text-align: center;
    //   font-size: calc(var(--primaryFont) + 2px);
    // }
  }
  .stepCommonForm {
    display: flex;
    flex-direction: column;
    gap: 30px;
    width: 100%;
    .editOrgReactSelect>div > div{
      cursor: pointer;
    }

    input {
      width: 100%;
      border-radius: 4px;
      padding: 10px;
      border: 1px solid var(--textColor);
    }
    .selectUi{
      width: 100%;
      max-width: 250px;
      label{
        display: flex !important;
        align-items: center;
      }
    }
    .pageBtn {
      justify-content: right;
      display: flex;
      align-items: center;
      width: 100%;
    }
  }
}
@media screen and (max-width: 550px) {
  .stepCommonInner {
    .stepCommonForm {
      padding: 0 20px;
    }
  }
}
