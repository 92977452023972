.formGroup {
  width: 100%;
  // padding-bottom: 5px;
  label {
    max-width: 80%;
    line-height: normal;
    text-align: left;
    width: 100%;
    font-weight: normal;
    sup {
      color: var(--redColor);
    }
  }
  input {
    border: 1px solid var(--blackColor);
  }
  .svg_input_icon {
    position: absolute;
    left: 5px;
    top: 9px;
    max-width: 28px;
  }
  .ipWithIcon {
    padding-left: 35px !important;
  }
  input[type="date"] {
    text-transform: uppercase;
  }
}
